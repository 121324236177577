/*
  ========================================================
      MAIN COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col, Grid, Collapse, Glyphicon } from 'react-bootstrap';
import ButtonPurple from '../../../Main/Components/reused/ButtonPurple';
import injectStyle from 'react-jss';
import axios from 'axios';

const style = {
  title: {
    fontSize: '1.5em',
    marginBottom: '20px'
  },
  container: {
    marginBottom: '50px'
  },
  applyButton: {
    marginLeft: '20px'
  },
  applyFormDiv: {
    marginTop: '50px'
  },
  input: {
    outline: 'none',
    margin: '10px 0',
    display: 'block',
    width: '80%',
    fontSize: '1.2em',
    borderRadius: '5px',
    color: 'black',
    padding: '0.8em',
    // marginLeft: '5px',
    '&::placeholder': {
      color: 'lightgrey'
    },
    '@media (max-width:768px)': {
      width: '100%'
    }
  },
  textarea: {
    height: '100px',
    resize: 'none',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'blue'
    }
  },

  applicationFile: {
    '&:disabled': {
      backgroundColor: 'white'
    }
  },

  headerTitle: {
    fontSize: '2.5em',
    marginBottom: '50px',
    fontWeight: 'bold'
  },

  fileChooser: {
    position: 'absolute',
    // top: '21px',
    // right: '65px',
    left: '70%',
    top: '15%',
    backgroundColor: 'rgb(88, 85, 160)',
    color: 'white',
    borderRadius: '5px',
    width: '90px',
    height: '30px',
    padding: '6px',
    fontSize: '11px',
    border: 'none',
    outline: 'none'
  }
};

class Apply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      file: '',
      phone: '',
      applyCollapsed: false,
      sending: false,
      emptyValues: true,
      successMessage: false,
      errorMessage: false
    };
  }
  componentDidMount = () => {
    console.log('API: ', global.api);
  };

  onChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      this.checkEmptyValues
    );
  };

  onFileChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.files[0]
      },
      this.checkEmptyValues
    );
  };
  checkEmptyValues = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.message === '' ||
      this.state.file === '' ||
      this.state.phone === ''
    ) {
      this.setState({
        emptyValues: true
      });
    } else
      this.setState({
        emptyValues: false
      });
  };

  sendData = () => {
    //Forming data
    let formDate = new FormData();
    formDate.append('name', this.state.name);
    formDate.append('email', this.state.email);
    formDate.append('phone', this.state.phone);
    formDate.append('message', this.state.message);
    formDate.append('file', this.state.file, this.state.file.name);

    //Setting sending flag to true
    this.setState({
      sending: true
    });

    axios
      .post(global.api + 'applyanyway.php', formDate, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(resposne => {
        if (resposne.data === 'success') {
          this.setState(
            {
              sending: false,
              successMessage: true
            },
            () =>
              setTimeout(() => this.setState({ successMessage: false }), 5000)
          );
          this.clearInputValues();
        }
        if (resposne.data === 'error') {
          this.setState(
            {
              sending: false,
              errorMessage: true
            },
            () => setTimeout(() => this.setState({ errorMessage: false }), 5000)
          );
        }
      })
      .catch(error => {
        this.setState(
          {
            sending: false,
            errorMessage: true
          },
          () => setTimeout(() => this.setState({ errorMessage: false }), 5000)
        );
      });
  };

  clearInputValues = () => {
    this.setState({
      name: '',
      email: '',
      phone: '',
      message: '',
      file: {
        name: ''
      },
      emptyValues: true
    });
  };

  collapseApply = () => {
    this.setState({
      applyCollapsed: !this.state.applyCollapsed
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <Grid>
        <Row className={classes.container}>
          <Col sm={12} className={classes.headerTitle}>
            Unsoliced application:
          </Col>
          <Col sm={12} className={classes.title} onClick={this.collapseApply}>
            Apply at QBits even though you dont find a suitable position for
            you. We are always looking for the best.
            <ButtonPurple style={style.applyButton}>
              Apply
              <span style={{ marginLeft: '5px' }}>
                <Glyphicon
                  glyph={this.state.applyCollapsed ? 'arrow-up' : 'arrow-down'}
                />
              </span>
            </ButtonPurple>
          </Col>
          <Col sm={12}>
            <Collapse in={this.state.applyCollapsed}>
              <Col sm={12}>
                <input
                  className={classes.input}
                  type="text"
                  placeholder="Your name"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                />
                <input
                  className={classes.input}
                  type="text"
                  placeholder="Your email address"
                  name="email"
                  onChange={this.onChange}
                  value={this.state.email}
                />
                <input
                  className={classes.input}
                  type="number"
                  placeholder="Your phone number"
                  name="phone"
                  onChange={this.onChange}
                  value={this.state.phone}
                />
                <textarea
                  className={`${classes.textarea} ${classes.input}`}
                  placeholder="Your message here"
                  name="message"
                  onChange={this.onChange}
                  value={this.state.message}
                />
                <div style={{ position: 'relative' }}>
                  <input
                    className={`${classes.input} ${classes.applicationFile}`}
                    type="text"
                    disabled
                    placeholder="*Application file"
                    value={this.state.file.name}
                  />
                  <div>
                    <label>
                      <input
                        type="file"
                        placeholder="choose"
                        className={classes.fileChooser}
                        name="file"
                        onChange={this.onFileChange}
                      />
                      <span
                        className={`btn btn-primary  ${classes.fileChooser}`}
                      >
                        CHOOSE FILE
                      </span>
                    </label>
                  </div>
                </div>

                <p
                  style={{
                    display:
                      this.state.successMessage || this.state.errorMessage
                        ? 'block'
                        : 'none',
                    fontSize: '1.5em',
                    color: this.state.successMessage ? '#71CF37' : 'red'
                  }}
                >
                  {this.state.successMessage
                    ? 'Contact information is received successfully'
                    : 'Error while sending your contact form, please try again.'}
                </p>

                <ButtonPurple
                  style={{ width: '150px', padding: '12px', marginTop: '10px' }}
                  disabled={this.state.emptyValues || this.state.sending}
                  onClick={this.sendData}
                >
                  {this.state.sending ? 'Sending...' : 'Send'}
                </ButtonPurple>
              </Col>
            </Collapse>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default injectStyle(style)(Apply);
