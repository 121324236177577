/*
  ========================================================
      HEADER ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import injectStyle from 'react-jss';
import Technology from './technology';

const style = {
  container: {
    display: 'inline-block',
    whiteSpace: 'no-wrap',
    // backgroundColor: 'red',
    width: '100%',
    //padding: '2vw',
    marginLeft: '2vw'
  },
  item: {
    backgroundColor: 'rgb(88, 85, 160)',
    margin: '10px 10px',
    borderRadius: '5px',
    padding: '10px 25px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  },
  title: {
    color: 'white',
    fontSize: '1.2em'
  },
  ul: {
    listStyle: 'none',
    padding: '0px',
    color: 'white'
  }
};

const technologies = [
  {
    title: 'Frontend',
    technologies: [
      'JavaScript',
      'HTML 5',
      'CSS 3',
      'Angular',
      'Vue',
      'D3',
      'jQuery'
    ]
  },
  {
    title: 'Backend',
    technologies: ['PHP', 'Laravel 5', 'Java', 'Node JS', 'Express', 'Symfony']
  },
  {
    title: 'Mobile',
    technologies: ['Android', 'Swift', 'Java', 'React Native', 'Xcode']
  },
  {
    title: 'Blockchain',
    technologies: ['Ethereum', 'Truffle', 'MetaMask', 'Web 3', 'Node JS']
  },
  {
    title: 'Database',
    technologies: ['Cassandra', 'MySQL', 'MongoDB', 'SQL Server']
  }
];
class PortofolioHeader extends Component {
  render() {
    let { classes } = this.props;
    return (
      <Row className={classes.container}>
        {technologies.map((item, index) => {
          return (
            <Col sm={2} className={classes.item} key={index}>
              <Technology items={item.technologies} title={item.title} />
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default injectStyle(style)(PortofolioHeader);
