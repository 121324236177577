import React, { Component } from 'react';
import ButtonPurple from './../../Main/Components/reused/ButtonPurple';
import axios from 'axios';
import { Col, Row, Modal } from 'react-bootstrap';

import injectStyle from 'react-jss';
import dismiss from './dismiss.png';

const style = {
  description: {
    fontSize: '1.2em',
    margin: '10px 0px'
  },
  input: {
    height: '50px',
    width: '100%',
    margin: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    padding: '15px',
    '&:disabled': {
      backgroundColor: 'white'
    }
  }
};

class ChangePasswordModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      emptyValues: true,
      sending: false
    };
  }
  componentWillReceiveProps(props) {
    this.setState({
      ...props.item
    });
  }

  handleClose() {
    //Handle close modal from the parent component
    try {
      this.props.closeModal();
      this.clearValues();
    } catch (e) {
      console.log(e);
    }
  }

  clearValues = () => {
    this.setState({
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      emptyValues: true,
      sending: false
    });
  };

  saveData = e => {
    axios
      .post(global.api + 'changepassword.php', {
        ...this.state,
        ...JSON.parse(localStorage.getItem('credentials'))
      })
      .then(res => {
        console.log('Res of changing passoword: ', res);
        if (res.data === 'success') {
          this.props.logout();
        }
        if (res.data === 'error')
          alert('An error accour while changing password. Please try again.');
      });
  };

  onChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      this.calculateEmptyValues
    );
  };

  onChangeTextArea = e => {
    this.setState(
      {
        [e.target.name]: e.target.value.split('\n')
      },
      this.calculateEmptyValues
    );
  };

  calculateEmptyValues = () => {
    if (
      this.state.oldPassword === '' ||
      this.state.newPassword === '' ||
      this.state.newPassword2 === '' ||
      this.state.newPassword !== this.state.newPassword2
    ) {
      this.setState({
        emptyValues: true
      });
      return;
    } else this.state.emptyValues && this.setState({ emptyValues: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleClose}
          className={`${classes.modal} modal-container`}
          animation={true}
          bsSize="sm"
        >
          <Modal.Header style={{ borderColor: 'white' }}>
            <span
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => this.handleClose()}
            >
              <img src={dismiss} alt="dismiss" style={{ width: '15px' }} />
            </span>
            <span style={{ fontSize: '1.8em' }}>Change Password</span>
          </Modal.Header>

          <Modal.Body style={{ padding: '30px' }}>
            <Row>
              <Col sm={12}>
                <input
                  className={classes.input}
                  type="password"
                  name="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.onChange}
                  placeholder="Old Password"
                />
                <input
                  className={classes.input}
                  type="password"
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={this.onChange}
                  placeholder="New Password"
                />
                <input
                  className={classes.input}
                  type="password"
                  name="newPassword2"
                  value={this.state.newPassword2}
                  onChange={this.onChange}
                  placeholder="Re-enter new password"
                />
              </Col>
              <Col sm={10} smOffset={1}>
                <ButtonPurple
                  disabled={this.state.emptyValues}
                  style={{ width: '100%' }}
                  onClick={this.saveData}
                >
                  Save
                </ButtonPurple>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default injectStyle(style)(ChangePasswordModal);
