import React, { Component } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';

import injectStyle from 'react-jss';
import dismiss from './dismiss.png';
import Founder from '../Founder';

const style = {
  modal: {
    marginTop: '50px',
    '@media (max-width:576px)': {
      marginTop: '0px'
    }
  }
};

class FounderModal extends Component {
  handleClose() {
    //Handle close modal from the parent component
    try {
      this.props.closeModal();
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const clas = this.props.classes;
    const { classes, name, position, description } = this.props.info;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleClose}
          className={clas.modal}
          // style={{ marginTop: '50px' }}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header> */}
          <Modal.Header style={{ borderColor: 'white' }}>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
            <span
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => this.handleClose()}
            >
              <img src={dismiss} alt="dismiss" style={{ width: '15px' }} />
            </span>
          </Modal.Header>

          <Modal.Body style={{ padding: '30px' }}>
            <Row>
              <Col sm={4} style={{ marginBottom: '20px' }}>
                <Founder {...this.props.info} modal={true} />
              </Col>
              <Col sm={8}>
                <p className={classes.name}> {name}</p>
                <p className={classes.position}> {position}</p>
                <p className={classes.description}>{description}</p>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}
export default injectStyle(style)(FounderModal);
