/*
  ========================================================
    TECHNOLOGY COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import injectStyle from 'react-jss';
import { Collapse } from 'react-bootstrap';

const style = {
  item: {
    backgroundColor: 'rgb(88, 85, 160)',
    margin: '10px 10px',
    borderRadius: '5px',
    padding: '10px 25px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  },
  title: {
    color: 'white',
    fontSize: '1.2em'
  },
  ul: {
    listStyle: 'none',
    padding: '0px',
    color: 'white'
  },
  showMore: {
    color: '#A9A9A9',
    cursor: 'pointer'
  }
};

class Technology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      title: props.title,
      extetended: false
    };
  }
  changeExtendable = () => {
    this.setState({
      extetended: !this.state.extetended
    });
  };

  render() {
    let { classes } = this.props;

    return (
      <div>
        <p className={classes.title}>{this.state.title}</p>
        <ul className={classes.ul}>
          {this.state.items
            .slice(0, this.state.items.length === 4 ? 4 : 3)
            .map(
              (item, index) =>
                (index < 3 || this.state.items.length === 4) && (
                  <li key={index}>{item}</li>
                )
            )}

          {this.state.items.length > 4 && (
            <Collapse in={this.state.extetended}>
              <div>
                {this.state.items
                  .slice(3, this.state.items.length)
                  .map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
              </div>
            </Collapse>
          )}
          {this.state.items.length > 3 && this.state.items.length !== 4 && (
            <li className={classes.showMore} onClick={this.changeExtendable}>
              {this.state.extetended ? 'Show less...' : 'Show more...'}
            </li>
          )}
        </ul>
      </div>
    );
  }
}
export default injectStyle(style)(Technology);
