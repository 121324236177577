import React, { Component } from 'react';
import { Row, Grid, Col } from 'react-bootstrap';
import injectStyle from 'react-jss';
import ButtonPurple from '../../../../pages/Main/Components/reused/ButtonPurple';
import axios from 'axios';

const style = {
  container: {
    minHeight: '80vh',
    position: 'relative',
    marginTop: '15vh'
  },
  applyInput: {
    height: '50px',
    maxWidth: '550px',
    width: '90%',
    margin: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    padding: '15px',
    '&:disabled': {
      backgroundColor: 'white'
    }
  },
  option: {
    backgroundColor: 'white'
  },
  textarea: {
    height: '150px',
    width: '100%',
    maxWidth: '100vw',

    margin: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    resize: 'none'
  },

  applicationFile: {
    position: 'relative'
  },

  fileChooser: {
    position: 'absolute',
    top: '21px',
    right: '65px',
    backgroundColor: 'rgb(88, 85, 160)',
    color: 'white',
    borderRadius: '5px',
    width: '90px',
    height: '30px',
    padding: '6px',
    fontSize: '11px',
    border: 'none',
    outline: 'none'
  },
  sendButtonDiv: {
    marginLeft: '10px'
  }
};

class Apply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        title: this.props.title,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        file: '',
        message: '',
        findus: ''
      },
      sending: false,
      emptyvalues: true,
      successMessage: false,
      errorMessage: false
    };
  }

  sendDate = () => {
    //Forming data
    let formDate = new FormData();
    formDate.append('title', this.state.data.title);
    formDate.append('firstName', this.state.data.firstName);
    formDate.append('lastName', this.state.data.lastName);
    formDate.append('email', this.state.data.email);
    formDate.append('phone', this.state.data.phone);
    formDate.append('file', this.state.data.file, this.state.data.file.name);
    formDate.append('message', this.state.data.message);
    formDate.append('findus', this.state.data.findus);

    //Setting sending flag to true
    this.setState({
      sending: true
    });

    //sending data
    axios
      .post(global.api + 'applyposition.php', formDate, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        if (res.data === 'success') {
          this.setState(
            {
              sending: false,
              emptyvalues: true,
              successMessage: true,
              data: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                file: { name: '' },
                message: '',
                findus: ''
              }
            },
            () =>
              setTimeout(() => this.setState({ successMessage: false }), 5000)
          );
        }
        if (res.data === 'error') {
          this.setState(
            {
              sending: false,
              emptyvalues: false,
              errorMessage: true
            },
            () => setTimeout(this.setState({ errorMessage: false }), 5000)
          );
        }
      })
      .catch(res =>
        this.setState({
          sending: false,
          emptyvalues: false
        })
      );
  };

  onChange = e =>
    this.setState(
      {
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value
        }
      },
      this.checkEmptyValues
    );

  onFileChange = e => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [e.target.name]: e.target.files[0]
        }
      },
      this.checkEmptyValues
    );
  };
  checkEmptyValues = () => {
    if (
      this.state.data.title === '' ||
      this.state.data.firstName === '' ||
      this.state.data.lastName === '' ||
      this.state.data.email === '' ||
      this.state.data.phone === '' ||
      this.state.data.file === '' ||
      this.state.data.message === '' ||
      this.state.data.findus === ''
    ) {
      this.setState({ emptyvalues: true });
    } else this.setState({ emptyvalues: false });
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid>
          <Row>
            <div className="col-sm-12 col-md-6">
              <input
                className={classes.applyInput}
                type="text"
                placeholder="*First name"
                name="firstName"
                value={this.state.data.firstName}
                onChange={this.onChange}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <input
                className={classes.applyInput}
                type="text"
                placeholder="*Last name"
                name="lastName"
                value={this.state.data.lastName}
                onChange={this.onChange}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <input
                className={classes.applyInput}
                type="text"
                placeholder="*Email address"
                name="email"
                value={this.state.data.email}
                onChange={this.onChange}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <input
                className={classes.applyInput}
                type="text"
                placeholder="*Phone number"
                name="phone"
                value={this.state.data.phone}
                onChange={this.onChange}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <select
                className={`${classes.applyInput}  ${classes.option}`}
                id="options"
                name="findus"
                onChange={this.onChange}
              >
                <option value="question" disabled selected>
                  How did you hear about us?
                </option>
                <option value="internet">Internet</option>
                <option value="anotherclient">Another Client</option>
                <option value="newspaper">Newspaper</option>
              </select>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="buttonInside">
                <input
                  className={`${classes.applyInput} ${classes.applicationFile}`}
                  type="text"
                  disabled
                  placeholder="*Application file"
                  value={this.state.data.file.name}
                />
                <div id="buttonDiv">
                  <label>
                    <input
                      type="file"
                      placeholder="choose"
                      className={classes.fileChooser}
                      name="file"
                      onChange={this.onFileChange}
                    />
                    <span className={`btn btn-primary  ${classes.fileChooser}`}>
                      CHOOSE FILE
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <Col sm={12}>
              <textarea
                placeholder="*Tell us a bit about yourself"
                className={`${classes.applyInput} ${classes.textarea} `}
                name="message"
                value={this.state.data.message}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12}>
              <p
                style={{
                  display:
                    this.state.successMessage || this.state.errorMessage
                      ? 'block'
                      : 'none',
                  fontSize: '1.5em',
                  color: this.state.successMessage ? '#71CF37' : 'red'
                }}
              >
                {this.state.successMessage
                  ? 'Contact information is received successfully'
                  : 'Error while sending your contact form, please try again.'}
              </p>
            </Col>
            <Col sm={12} className={classes.sendButtonDiv}>
              <ButtonPurple
                style={{
                  //backgroundColor: '#56358B',
                  width: '150px',
                  padding: '15px 50px'
                }}
                onClick={this.sendDate}
                disabled={this.state.emptyvalues}
              >
                {this.state.sending ? 'Sending...' : 'SEND'}
              </ButtonPurple>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectStyle(style)(Apply);
