import React, { Component } from 'react';
import { Col, Row, Grid, Collapse } from 'react-bootstrap';
import injectStyle from 'react-jss';
import ButtonPurple from '../Main/Components/reused/ButtonPurple';
import axios from 'axios';
import EditModal from './Modal';
import ChangePasswordModal from './ChangePasswordModal';

//Importing images

import minusImage from '../Career/Components/Positions/images/minus.png';
import plusImage from '../Career/Components/Positions/images/plus.png';
import editImage from './edit.png';

const style = {
  container: {
    // minHeight: '100vh',
    height: 'fit-content',
    paddingTop: '20vh',
    backgroundColor: '#3f0f6d'
  },
  title: {
    fontSize: '2.5em',
    color: 'white',
    cursor: 'pointer'
  },
  pos: {
    color: 'white',
    fontSize: '1.5em',
    padding: '20px'
  },
  deleteButton: {
    float: 'right',
    cursor: 'pointer',
    fontSize: '2.2em'
  },
  posdescription: {
    fontSize: '0.7em',
    margin: '15px',
    width: '80%',
    color: 'grey',
    maxHeight: '150px',
    overflow: 'scroll',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
      width: '0px'
    }
  },
  plusminusimage: {
    width: '30px',
    float: 'right',
    cursor: 'pointer'
  },
  button: {
    margin: '30px',
    marginRight: '0px'
  },
  noPositionText: {
    color: 'white',
    fontSize: '1.5em',
    margin: '30px',
  }
};

class Positions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: [],
      expanded: false,
      modalOpened: false,
      changePasswordModalOpen: false,
      editedItem: {}
    };
  }

  showHide = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  componentWillMount() {
    this.getPositions();
  }
  componentWillReceiveProps() {
    this.getPositions();
  }
  closeEditModal = () => {
    this.setState({
      modalOpened: false
    });
  };
  closeChangePasswordModal = () => {
    this.setState({
      changePasswordModalOpen: false
    });
  };
  getPositions = () => {
    axios.get(global.api + 'positions.php').then(res => {

      this.setState({
        positions: res.data !== 'error' ? [...res.data] : 'error'
      })
    });
  };
  closeModal = () => {
    this.setState({
      modalOpened: false
    });
  };

  deletePosition = postID => {
    console.log('Delete position ID: ', postID);
    if (window.confirm('Jeni te sigurte qe doni te fshini pozicionin? ')) {
      if (localStorage.getItem('credentials') !== null) {
        axios
          .post(global.api + 'deleteposition.php', {
            delID: postID,
            ...JSON.parse(localStorage.getItem('credentials'))
          })
          .then(res => {
            console.log(res);
            if (res.data === 'success') {
              this.getPositions();
            }
          });
      }
    }
  };
  changePassword = () => {
    this.setState({
      changePasswordModalOpen: true
    });
  };
  editPosition = item => {
    console.log('Item: ', item);

    //Making a post request to get the position details
    axios
      .post(global.api + 'position.php', {
        position: item.name
      })
      .then(res => {
        if (res.data !== 'false') {
          this.setState({
            modalOpened: true,
            editedItem: res.data
          });
        }
      });
  };
  render() {
    let { classes } = this.props;

    return (
      <div className={classes.container}>
        <Grid>
          <Row>
            <Col sm={12} style={{ marginBottom: '20px', textAlign: 'right' }}>
              <ButtonPurple
                className={classes.button}
                onClick={this.changePassword}
              >
                Change Password
              </ButtonPurple>
              <ButtonPurple
                className={classes.button}
                onClick={this.props.logout}
              >
                Logout
              </ButtonPurple>
            </Col>
          </Row>
          <Row onClick={this.showHide}>
            <Col sm={10}>
              <p className={classes.title}>
                {this.state.expanded ? 'Positions' : 'Show Positions'}
              </p>
            </Col>

            <Col sm={2}>
              <img
                alt="img"
                className={classes.plusminusimage}
                src={this.state.expanded ? minusImage : plusImage}
                title={
                  this.state.expanded ? 'Hide positions' : 'Show Positions'
                }
              />
            </Col>
          </Row>
          <Collapse in={this.state.expanded} timeout={200}>
            <Row>

              {this.state.positions !== 'error' ? this.state.positions.map((item, index) => {
                return (
                  <Col sm={12} key={index} className={classes.pos}>
                    <span>{item.name}</span>
                    <span
                      className={classes.deleteButton}
                      title={`Delete position: ${item.name}`}
                      onClick={() => this.deletePosition(item.id)}
                    >
                      -
                    </span>
                    <span
                      className={classes.deleteButton}
                      title={`Edit Position: ${item.name}`}
                      onClick={() => this.editPosition(item)}
                    >
                      <img
                        alt="img"
                        src={editImage}
                        style={{ width: '20px', marginRight: '10px' }}
                      />
                    </span>

                    <p className={classes.posdescription}>{item.description}</p>
                  </Col>
                );
              }) : <span className={classes.noPositionText} >No positions</span>}
            </Row>
          </Collapse>
        </Grid>
        <EditModal
          show={this.state.modalOpened}
          // closeModal={this.closeEditModal}
          item={this.state.editedItem}
          updatePositions={this.getPositions}
          closeModal={this.closeModal}
        />
        <ChangePasswordModal
          show={this.state.changePasswordModalOpen}
          closeModal={this.closeChangePasswordModal}
          logout={this.props.logout}
        />
      </div>
    );
  }
}

export default injectStyle(style)(Positions);
