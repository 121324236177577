export default [
  {
    image: require('./logo/php.png'),
    imageHover: require('./logo/php_hover.png'),
    normal: {
      width: '50px',
      height: 'auto'
    },
    hover: {
      width: '40px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/laravel gr.png'),
    imageHover: require('./logo/laravel_hover.png'),
    normal: {
      width: '100px',
      height: 'auto'
    },
    hover: {
      width: '80px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/symphony gr.png'),
    imageHover: require('./logo/symphony gr.png'),
    title: 'Symphony',
    normal: {
      width: '40px',
      height: 'auto'
    },
    hover: {
      width: '40px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/node gr.png'),
    imageHover: require('./logo/node_hover.png'),
    normal: {
      width: '65px',
      height: 'auto'
    },
    hover: {
      width: '65px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/java gr.png'),
    imageHover: require('./logo/java_hover.png'),
    normal: {
      width: '40px',
      height: 'auto'
    },
    hover: {
      width: '30px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/express gr.png'),
    imageHover: require('./logo/express_hover.png'),
    normal: {
      width: '100px',
      height: 'auto'
    },
    hover: {
      width: '100px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/mongodb.png'),
    imageHover: require('./logo/mongodb_hover.png'),
    normal: {
      width: '80px',
      height: 'auto'
    },
    hover: {
      width: '70px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/ms sql gr.png'),
    imageHover: require('./logo/sql_server_hover.png'),
    normal: {
      width: '80px',
      height: 'auto'
    },
    hover: {
      width: '80px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/cassandra gr.png'),
    imageHover: require('./logo/casandra_hover.png'),
    normal: {
      width: '65px',
      height: 'auto'
    },
    hover: {
      width: '65px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/mysql gr.png'),
    imageHover: require('./logo/mysql_hover.png'),
    normal: {
      width: '80px',
      height: 'auto'
    },
    hover: {
      width: '80px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/javascript.png'),
    imageHover: require('./logo/javascript_hover.png'),
    title: 'Javascript',
    normal: {
      width: '37px',
      height: 'auto'
    },
    hover: {
      width: '25px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/angular_gr.png'),
    imageHover: require('./logo/angular_hover.png'),
    title: 'Angular',
    normal: {
      width: '50px',
      height: 'auto'
    },
    hover: {
      width: '35px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/vue gr.png'),
    imageHover: require('./logo/vue_hover.png'),
    title: 'VUE',
    normal: {
      width: '80px',
      height: 'auto'
    },
    hover: {
      width: '55px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/html_css.png'),
    imageHover: require('./logo/html_css_hover.png'),
    title: 'HTML & CSS',
    normal: {
      width: '80px',
      height: 'auto'
    },
    hover: {
      width: '70px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/d3 gr.png'),
    imageHover: require('./logo/d3_hover.png'),
    title: 'D3',
    normal: {
      width: '50px',
      height: 'auto'
    },
    hover: {
      width: '35px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/jquery.png'),
    imageHover: require('./logo/jquery_hover.png'),
    normal: {
      width: '120px',
      height: 'auto'
    },
    hover: {
      width: '85px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/etherum col.png'),
    imageHover: require('./logo/ethereum_color.png'),
    title: 'Ethereum',
    normal: {
      width: '30px',
      height: 'auto'
    },
    hover: {
      width: '25px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/metamask.png'),
    imageHover: require('./logo/metamask_hover.png'),
    normal: {
      width: '140px',
      height: 'auto'
    },
    hover: {
      width: '115px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/truffle.png'),
    imageHover: require('./logo/truffle_hover.png'),
    normal: {
      width: '60px',
      height: 'auto'
    },
    hover: {
      width: '40px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/node.png'),
    imageHover: require('./logo/node_blockchain_hover.png'),
    title: 'Node JS',
    normal: {
      width: '50px',
      height: 'auto'
    },
    hover: {
      width: '40px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/web3.png'),
    imageHover: require('./logo/web3_hover.png'),
    title: 'Web3',
    normal: {
      width: '80px',
      height: 'auto'
    },
    hover: {
      width: '75px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/android_gr.png'),
    imageHover: require('./logo/android_hover.png'),
    title: 'Android',
    normal: {
      width: '50px',
      height: 'auto'
    },
    hover: {
      width: '40px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/app_store.png'),
    imageHover: require('./logo/app_store_hover.png'),
    title: 'X-Code',
    normal: {
      width: '70px',
      height: 'auto'
    },
    hover: {
      width: '47px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/swift.png'),
    imageHover: require('./logo/swift_hover.png'),
    title: 'Swift',
    normal: {
      width: '50px',
      height: 'auto'
    },
    hover: {
      width: '40px',
      height: 'auto'
    }
  },
  {
    image: require('./logo/react_native.png'),
    imageHover: require('./logo/react_native_hover.png'),
    title: 'React Native',
    normal: {
      width: '100px',
      height: 'auto'
    },
    hover: {
      width: '75px',
      height: 'auto'
    }
  }
];
