/*
  ========================================================
      Footer ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import injectStyle from 'react-jss';
import Technologies from './technologiesData';

let style = {
  container: {
    position: 'relative'
  },
  title: {
    margin: '50px',
    textAlign: 'center',
    fontSize: '1.2em',
    position: 'relative',
    '@media (max-width: 991px) ': {
      display: 'none'
    }
  },
  image: {
    width: '70px',
    // height: '70px',
    opacity: '0.8',
    position: 'absolute',
    transition: '0.8s',
    filter: 'grayscale(100%)',
    transition: '1s',
    '@media (max-width:768px)': {
      padding: '5px'
    },
    '@media (min-width:769px) AND (max-width: 991px) ': {
      padding: '2px'
    },
    '&:hover': {
      filter: 'grayscale(0)',
      opacity: '1'
    }
  },
  img0: {
    left: '50px',
    top: '-100px',
    '@media (max-width:768px)': {
      left: '50px',
      top: '-200px'
    },
    '@media (min-width:769px) AND (max-width: 991px) ': {
      left: '10px',
      top: '-140px'
    }
  },
  img1: {
    left: '200px',
    top: '-70px',
    '@media (max-width:768px)': {
      left: '100px',
      top: '-100px'
    },
    '@media (min-width:769px) AND (max-width: 991px) ': {
      left: '50px',
      top: '-20px'
    }
  },
  img2: {
    right: '50px',
    top: '-100px',
    '@media (max-width:768px)': {
      right: '50px',
      top: '-200px'
    },
    '@media (min-width:769px) AND (max-width: 991px) ': {
      right: '10px',
      top: '-140px'
    }
  },
  img3: {
    right: '200px',
    top: '-70px',
    '@media (max-width:768px)': {
      right: '100px',
      top: '-100px'
    },
    '@media (min-width:769px) AND (max-width: 991px) ': {
      right: '50px',
      top: '-20px'
    }
  }
};

class FooterItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: []
    };
  }

  randomImages = () => {
    let images = [];
    let indexes = '';
    let nr = 0;
    let length = Technologies.length;
    while (nr < 4) {
      let rand = Math.ceil(Math.random() * length) - 1;
      if (indexes.indexOf(rand) === -1) {
        indexes += rand.toString() + ',';
        images.push(Technologies[rand]);
        nr++;
      }
    }
    this.setState({ images });
  };
  componentDidMount() {
    this.randomImages();
    setInterval(() => {
      this.randomImages();
    }, 2000);
  }
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.images.map((item, index) => {
          return (
            <div>
              <img
                alt="img"
                title={item.title}
                className={`${classes.image} ${classes[`img${index}`]}`}
                src={item.imageHover}
              />
            </div>
          );
        })}

        <p className={classes.title}>
          Some of the popular technologies we offer
        </p>
      </div>
    );
  }
}

export default injectStyle(style)(FooterItem);
