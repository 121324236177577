import React, { Component } from 'react';
import { Row, Col, Grid, Collapse } from 'react-bootstrap';
import ButtonPurple from '../../../Main/Components/reused/ButtonPurple';
import injectStyle from 'react-jss';
import { Link } from 'react-router-dom';

//Importing images

import minusImage from './images/minus.png';
import plusImage from './images/plus.png';

const style = {
  container: {
    backgroundColor: 'white'
  },
  hr: { marginTop: '20px' },
  plusMinusImage: {
    float: 'right',
    width: '20px',
    height: '20px',
    cursor: 'pointer'
  },
  title: {
    color: 'rgb(103, 101, 168)',
    fontSize: '1.3em',
    cursor: 'pointer'
  },
  description: {
    margin: '30px 0px',
    transition: '1s'
  }
};

class Position extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  handeExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <Grid
        fluid
        className={classes.container}
        onMouseLeave={() => this.setState({ expanded: false })}
      >
        <Row>
          <Col sm={12} className={classes.title} onClick={this.handeExpand}>
            {this.props.info.name}
            <img
              alt="img"
              className={classes.plusMinusImage}
              src={this.state.expanded ? minusImage : plusImage}
              onClick={this.handeExpand}
            />
          </Col>
        </Row>
        <Collapse in={this.state.expanded} timeout={500}>
          <Row>
            <Col sm={10} className={classes.description}>
              {this.props.info.description}
              <div>
                <Link
                  to={{
                    pathname: '/apply/' + this.props.info.name
                    // paramx: { apply: this.props.info.apply }
                  }}
                >
                  <ButtonPurple style={{ marginTop: '30px' }}>
                    READ MORE
                  </ButtonPurple>
                </Link>
              </div>
            </Col>
          </Row>
        </Collapse>
        <Row>
          <Col sm={12}>
            <hr className={classes.hr} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default injectStyle(style)(Position);
