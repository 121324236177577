import React, { Component } from 'react';
import ButtonPurple from './../../Main/Components/reused/ButtonPurple';
import axios from 'axios';
import { Col, Row, Modal } from 'react-bootstrap';

import injectStyle from 'react-jss';
import dismiss from './dismiss.png';

const style = {
  modal: {
    marginTop: '0px'
  },
  description: {
    fontSize: '1.2em',
    margin: '10px 0px'
  },
  input: {
    height: '50px',
    width: '100%',
    margin: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    padding: '15px',
    '&:disabled': {
      backgroundColor: 'white'
    }
  },
  option: {
    backgroundColor: 'white'
  },
  textarea: {
    height: '150px',
    width: '100%',
    maxWidth: '100vw',

    margin: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    resize: 'none'
  }
};

class FounderModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...this.props.item,
      emptyValues: false,
      sending: false
    };
  }
  componentWillReceiveProps(props) {
    this.setState({
      ...props.item
    });
  }

  handleClose() {
    //Handle close modal from the parent component
    try {
      this.props.closeModal();
    } catch (e) {
      console.log(e);
    }
  }
  saveData = e => {
    axios
      .post(global.api + 'editPosition.php', {
        ...this.state,
        ...JSON.parse(localStorage.getItem('credentials'))
      })
      .then(res => {
        if (res.data === 'success') {
          this.props.updatePositions();
          this.props.closeModal();
        }
        if (res.data === 'error')
          alert('An error accour while changing data. Please try again.');
      });
  };

  onChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      this.calculateEmptyValues
    );
  };

  onChangeTextArea = e => {
    this.setState(
      {
        [e.target.name]: e.target.value.split('\n')
      },
      this.calculateEmptyValues
    );
  };

  calculateEmptyValues = () => {
    if (
      this.state.title === '' ||
      this.state.description === '' ||
      this.state.applyBellow === '' ||
      this.state.applyBellowText === '' ||
      this.state.responsibilities === '' ||
      this.state.benefits === '' ||
      this.state.howtoapply === '' ||
      this.state.qualifications === ''
    ) {
      this.setState({
        emptyValues: true
      });
      return;
    } else this.state.emptyValues && this.setState({ emptyValues: false });
  };

  render() {
    const { classes } = this.props;
    if (!this.state.responsibilities) return <div />;
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleClose}
          className={`${classes.modal} modal-container`}
          animation={true}
          bsSize="lg"
        >
          <Modal.Header style={{ borderColor: 'white' }}>
            <span
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => this.handleClose()}
            >
              <img src={dismiss} alt="dismiss" style={{ width: '15px' }} />
            </span>
          </Modal.Header>

          <Modal.Body style={{ padding: '30px' }}>
            <Row>
              <Col sm={12} className={classes.description}>
                <Col sm={4}>
                  <label>Title:</label>
                </Col>
                <Col sm={8}>
                  <input
                    className={classes.input}
                    type="text"
                    name="title"
                    onChange={this.onChange}
                    value={this.state.title}
                  />
                </Col>
              </Col>
              <Col sm={12} className={classes.description}>
                <Col sm={4}>
                  <label>Description:</label>
                </Col>
                <Col sm={8}>
                  <textarea
                    className={`${classes.input} ${classes.textarea}`}
                    type="text"
                    name="description"
                    onChange={this.onChange}
                    value={this.state.description}
                  />
                </Col>
              </Col>
              <Col sm={12} className={classes.description}>
                <Col sm={4}>
                  <label>Apply Bellow text:</label>
                </Col>
                <Col sm={8}>
                  <textarea
                    className={`${classes.input} ${classes.textarea}`}
                    type="text"
                    name="applyBellow"
                    onChange={this.onChange}
                    value={this.state.applyBellow}
                  />
                </Col>
              </Col>
              <Col sm={12} className={classes.description}>
                <Col sm={4}>
                  <label>Responsibilities:</label>
                </Col>
                <Col sm={8}>
                  <textarea
                    className={`${classes.input} ${classes.textarea}`}
                    type="text"
                    name="responsibilities"
                    onChange={this.onChangeTextArea}
                    value={this.state.responsibilities.join('\n')}
                    placeholder="New line separator"
                  />
                </Col>
              </Col>
              <Col sm={12} className={classes.description}>
                <Col sm={4}>
                  <label>Benefits:</label>
                </Col>
                <Col sm={8}>
                  <textarea
                    className={`${classes.input} ${classes.textarea}`}
                    type="text"
                    name="benefits"
                    onChange={this.onChangeTextArea}
                    value={this.state.benefits.join('\n')}
                    placeholder="New line separator"
                  />
                </Col>
              </Col>
              <Col sm={12} className={classes.description}>
                <Col sm={4}>
                  <label>How to apply:</label>
                </Col>
                <Col sm={8}>
                  <textarea
                    className={`${classes.input} ${classes.textarea}`}
                    type="text"
                    name="howtoapply"
                    onChange={this.onChangeTextArea}
                    value={this.state.howtoapply.join('\n')}
                    placeholder="New line separator"
                  />
                </Col>
              </Col>
              <Col sm={12} className={classes.description}>
                <Col sm={4}>
                  <label>Qualifications:</label>
                </Col>
                <Col sm={8}>
                  <textarea
                    className={`${classes.input} ${classes.textarea}`}
                    type="text"
                    name="qualifications"
                    onChange={this.onChangeTextArea}
                    value={this.state.qualifications.join('\n')}
                    placeholder="New line separator"
                  />
                </Col>
              </Col>
              <Col sm={8} smOffset={4}>
                <ButtonPurple
                  disabled={this.state.emptyValues}
                  style={{ width: '100%' }}
                  onClick={this.saveData}
                >
                  Save
                </ButtonPurple>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default injectStyle(style)(FounderModal);
