/*
  ========================================================
      BODY ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import injectStyle from 'react-jss';
import { renderToStaticMarkup } from 'react-dom/server';
import ButtonPurple from '../reused/ButtonPurple';
import CenterComponent from './Subcomponents/Center';
import FooterComponent from './Subcomponents/Footer';
import HeaderCompoent from './Subcomponents/Header';

class BackGroundImage extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        // shape-rendering="crispEdges"
        preserveAspectRatio="viewBox"
        width="100%"
      >
        <circle fill="rgb(46, 43, 107)" cx="150" cy="900" r="900" />
      </svg>
    );
  }
}
const style = {
  container: {
    backgroundColor: 'whitesmoke',
    minHeight: '100vh',
    height: 'fitContent',
    // width: '98vw',
    position: 'relative',
    background: `url("data:image/svg+xml,${encodeURIComponent(
      renderToStaticMarkup(<BackGroundImage />)
    )}")`,
    backgroundSize: 'cover'
  },
  footerButtonDiv: {
    position: 'absolute',
    left: '50%',
    bottom: '0px',
    zIndex: '2',
    transform: 'translate(-50%, 50%)'
  },
  header: {
    position: 'relative',
    width: '90%',
    transform: 'translate(5%, -10%)'
  }
};

class Portofolio extends Component {
  render() {
    let { classes } = this.props;

    return (
      <div className={classes.container} id="portofolioComponent">
        <Grid>
          <div className={classes.header}>
            <HeaderCompoent />
          </div>
          <CenterComponent />
          <div className={classes.footerDiv}>
            <FooterComponent />
          </div>
          <div className={classes.footerButtonDiv}>
            <ButtonPurple onClick={() => this.props.goto('contactComponent')}>
              GET IN TOUCH
            </ButtonPurple>
          </div>
        </Grid>
      </div>
    );
  }
}
export default injectStyle(style)(Portofolio);
