/*
  ========================================================
      BODY ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import injectStyle from 'react-jss';

let items = [
  {
    title: 'Development.',
    description: 'Full range of specializations',
    image: require('../image/development.png')
  },
  {
    title: 'Maintenance.',
    description: 'Ongoing for any job needed',
    image: require('../image/maintainance.png')
  },
  {
    title: 'Support.',
    description: 'Ongoing from any of our work',
    image: require('../image/support.png')
  }
];

let style = {
  image: {
    height: '30px',
    width: '30px'
  },
  component: {
    display: 'inline-block'
  },
  title: {
    fontSize: '1.3em'
  },
  description: {
    color: 'grey'
  },
  row: {
    margin: '20px'
  },
  item: {
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  }
};

class HeaderItem extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div>
        <Grid>
          <Row className={classes.row}>
            {items.map((item, index) => {
              return (
                <Col className={classes.item} sm={4} key={index}>
                  <Row>
                    <Col sm={1}>
                      <img
                        alt="item"
                        className={classes.image}
                        src={item.image}
                      />
                    </Col>
                    <Col sm={10}>
                      <Col sm={12} className={classes.title}>
                        {item.title}
                      </Col>
                      <Col sm={12} className={classes.description}>
                        {item.description}
                      </Col>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectStyle(style)(HeaderItem);
