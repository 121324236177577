/*
  ========================================================
     Founder COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import injectStyle from 'react-jss';

const style = {
  col: {
    zIndex: '2'
  },
  values: {
    boxshadow: '3px 3px 3px 3px rgba(0,0,0, 1) ',
    padding: '1.3em',
    marginTop: '15px',
    // marginTop: '10px',
    // marginLeft: '10%',
    width: '100%',
    minHeight: '150px',
    height: 'fit-content',
    backgroundColor: 'white',
    borderRadius: '5px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  text: {
    fontSize: '14px',
    lineHeight: '1.2em',
    color: 'rgba(0, 0, 0, 0.432)'
  },
  emojisImg: {
    width: '20px',
    height: '20px'
  }
};

class Value extends Component {
  render() {
    let { classes, value } = this.props;

    return (
      <Col sm={4} className={classes.col}>
        <div className={classes.values}>
          <p className={classes.title}>
            <img alt="item" className={classes.emojisImg} src={value.image} />
            {value.title}
          </p>
          <p className={classes.text}>{value.text}</p>
        </div>
      </Col>
    );
  }
}
export default injectStyle(style)(Value);
