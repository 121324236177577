import React, { Component } from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import injectStyle from 'react-jss';
import ButtonPurple from '../Main/Components/reused/ButtonPurple';
import axios from 'axios';

const style = {
  container: {
    backgroundColor: '#3f0f6d',
    minHeight: '100vh',
    color: 'white',
    height: 'fit-content',
    paddingTop: '20vh'
  },
  title: {
    fontSize: '2.5em'
  },
  input: {
    height: '50px',
    maxWidth: '550px',
    width: '90%',
    margin: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    padding: '15px',
    color: 'black',
    '&:disabled': {
      backgroundColor: 'white'
    }
  },
  textarea: {
    height: '150px',
    color: 'black',
    width: '90%',
    maxWidth: '100vw',
    margin: '10px',
    padding: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    resize: 'none'
  },
  applyFields: {
    marginTop: '50px',
    marginBottoml: '10px'
  }
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positionName: '',
      positionDescription: '',
      positionDescriptionApply: '',
      applyBellowText: '',
      responsibilities: '',
      benefits: '',
      qualifications: '',
      howtoapply: '',
      emptyValues: true,
      registering: false
    };
  }
  onChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      this.calculateEmptyValues
    );
  };

  calculateEmptyValues = () => {
    if (
      this.state.positionName === '' ||
      this.state.positionDescription === '' ||
      this.state.positionDescriptionApply === '' ||
      this.state.applyBellowText === '' ||
      this.state.responsibilities === '' ||
      this.state.benefits === '' ||
      this.state.howtoapply === '' ||
      this.state.qualifications === ''
    ) {
      this.setState({
        emptyValues: true
      });
      return;
    } else this.state.emptyValues && this.setState({ emptyValues: false });
  };

  registerPosition = () => {
    if (
      !this.state.emptyValues &&
      localStorage.getItem('credentials') !== null
    ) {
      let data = {
        positionName: this.state.positionName,
        positionDescription: this.state.positionDescription,
        positionDescriptionApply: this.state.positionDescriptionApply,
        applyBellowText: this.state.applyBellowText,
        responsibilities: this.state.responsibilities.split('\n'),
        benefits: this.state.benefits.split('\n'),
        howtoapply: this.state.howtoapply.split('\n'),
        qualifications: this.state.qualifications.split('\n'),
        ...JSON.parse(localStorage.getItem('credentials'))
      };
      this.setState({
        registering: true,
        emptyValues: true
      });

      //Posting data.
      axios
        .post(global.api + 'register.php', {
          ...data
        })
        .then(res => {
          console.log(res);
          if (res.data === 'success') {
            //Tell main component that a  new position is added to update other components
            this.props.positionAdded();

            this.setState({
              registering: false,
              positionName: '',
              positionDescription: '',
              positionDescriptionApply: '',
              applyBellowText: '',
              responsibilities: '',
              benefits: '',
              howtoapply: '',
              qualifications: '',
              emptyValues: true
            });
          }

          if (res.data === 'error') {
            //if an erro accour happen the data will not be empty but the registering state will be false
            setTimeout(() => {
              this.setState({
                registering: false,
                emptyValues: false
              });
            }, 500);
          }
        })
        .catch(console.log);
    }
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid>
          <Row>
            <Col sm={12}>
              <p className={classes.title}>Create a new position.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <input
                className={classes.input}
                type="text"
                placeholder="Position Name"
                name="positionName"
                value={this.state.positionName}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12} md={6}>
              <input
                className={classes.input}
                type="text"
                placeholder="Position Description"
                value={this.state.positionDescription}
                name="positionDescription"
                onChange={this.onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} className={classes.applyFields}>
              <p className={classes.title}>Apply</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <input
                type="text"
                placeholder="Position Description"
                className={classes.input}
                name="positionDescriptionApply"
                value={this.state.positionDescriptionApply}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12} md={6}>
              <input
                className={classes.input}
                type="text"
                placeholder="Apply Bellow Text"
                name="applyBellowText"
                value={this.state.applyBellowText}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12} md={6}>
              <label>Responsibilities: </label>
              <textarea
                className={classes.textarea}
                placeholder="Responsibilities, new line seperated"
                name="responsibilities"
                value={this.state.responsibilities}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12} md={6}>
              <label>Benefits: </label>
              <textarea
                className={classes.textarea}
                placeholder="Benefits, new line seperated"
                name="benefits"
                value={this.state.benefits}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12} md={6}>
              <label>How to apply: </label>
              <textarea
                className={classes.textarea}
                placeholder="How to apply, new line seperated"
                name="howtoapply"
                value={this.state.howtoapply}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12} md={6}>
              <label>Qualifications: </label>
              <textarea
                className={classes.textarea}
                placeholder="Qualifications, new line seperated"
                name="qualifications"
                value={this.state.qualifications}
                onChange={this.onChange}
              />
            </Col>
            <Col sm={12}>
              <ButtonPurple
                style={{ margin: '20px', width: '200px', height: '50px' }}
                onClick={this.registerPosition}
                disabled={this.state.emptyValues}
              >
                {this.state.registering ? 'Registering...' : 'Register'}
              </ButtonPurple>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectStyle(style)(Register);
