import React, { Component, Fragment } from 'react';
import Main from './Components/Main';
import Services from './Components/Services';
import Portofolio from './Components/Portofolio';
import About from './Components/About';
import Contact from './Components/Contact';
import TrackVisibility from 'react-on-screen';

//import { browserHistory } from '@version/react-router-v3';

class App extends Component {
  activeMenu = '';
  menusize = 'big';
  gotopvisible = false;

  componentDidMount() {
    const { location = {} } = this.props;
    const { paramx = {} } = location;
    const { name = false } = paramx;
    name && this.goto(name);

    //Changing page title
    document.title = 'QBits';
  }

  componentWillReceiveProps(props) {
    this.goto(props.location.paramx.name);
  }

  pageScrollHandler = yOffset => {
    this.menuCoordinates.map(item => {
      let menu = item.menu !== 'main' ? item.menu : '';
      if (
        yOffset > item.top - 300 &&
        yOffset < item.bottom - 300 &&
        menu !== this.activeMenu
      ) {
        //browserHistory.replace('/' + menu);
        this.activeMenu = menu;
        document.title =
          menu !== ''
            ? 'QBits - ' + menu.charAt(0).toUpperCase() + item.menu.substr(1)
            : 'QBits';
      }
      return null;
    });
  };

  goto = (ref, yOffset = 0) => {
    // document
    //   .getElementById(ref)
    //   .scrollIntoView({ behavior: 'smooth', block: 'start' });
    const srollDistance =
      // ReactDOM.findDOMNode(this[ref]).offsetTop -
      document.getElementById(ref).offsetTop -
      document.getElementById('mainMenu').clientHeight * 1.3 +
      yOffset;

    try {
      //this line will detect if browser is supported and execute the right statement
      this.unsoportedSmoothScrollBrowser()
        ? this.scrollWindow(0, srollDistance)
        : this.scrollWindow({ top: srollDistance, behavior: 'smooth' });
    } catch (e) {
      // this.scrollWindow(0, srollDistance);
      // console.log(e);
      window.scrollTo({ top: srollDistance, behavior: 'smooth' });
    }
  };

  render() {
    return (
      <Fragment>
        <TrackVisibility partialVisibility>
          <Main goto={this.goto} />
        </TrackVisibility>
        <Services goto={this.goto} />
        <Portofolio goto={this.goto} />
        <About />
        <Contact />
        {/* <FileInput /> */}
      </Fragment>
    );
  }
}

export default App;
