/*
  ========================================================
      MENU COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import ButtonPurple from '../Main/Components/reused/ButtonPurple';

import './style.css';
import logo from './images/menulogo.png';
import logoInvert from './images/menuLogoInvert.png';
import arrowUp_Icon from './images/arrow_up.png';
import { Link } from 'react-router-dom';

const menues = [
  {
    name: 'Services',
    pathname: '/',
    id: 'servicesComponent'
  },
  {
    name: 'Portofolio',
    pathname: '/',
    id: 'portofolioComponent'
  },
  {
    name: 'About',
    pathname: '/',
    id: 'aboutComponent'
  },
  {
    name: 'Values',
    pathname: '/',
    id: 'valuesMenu'
  },
  {
    name: 'Careers',
    pathname: '/careers',
    id: 'mainComponent'
  },
  {
    component: ButtonPurple,
    text: 'GET IN TOUCH',
    name: 'Contact',
    pathname: '/',
    id: 'contactComponent'
  }
];

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: this.props.getMenuSize(),
      gotopVisible: false,
      activeMenu: this.props.getActiveMenu(),
      navExpanded: false,
      whiteMenu: false
    };
    window.addEventListener('scroll', this.menuScroll);
  }

  mainComponentHeight = 0;

  componentDidMount(props) {
    this.calculateMainComponentHeight();
  }

  calculateMainComponentHeight = () => {
    //Calulate the height of the first componentn Main Component
    this.mainComponentHeight = document.getElementById(
      'mainComponent'
    ).clientHeight;
  };

  menuScroll = () => {
    let pageOffset = window.pageYOffset;
    pageOffset > this.mainComponentHeight * 0.8 && !this.state.whiteMenu
      ? this.setState({ whiteMenu: true })
      : pageOffset < this.mainComponentHeight * 0.8 &&
        this.state.whiteMenu &&
        this.setState({ whiteMenu: false });

    // if window width > 768 we will calculate the goTopVisible
    if (document.body.offsetWidth >= 768) {
      pageOffset > 500 && !this.state.gotopVisible
        ? this.setState({
            gotopVisible: true
          })
        : pageOffset <= 500 &&
          this.state.gotopVisible &&
          this.setState({
            gotopVisible: false
          });
    }
  };

  delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  stateChangeBlocked = false;

  componentWillUpdate(props) {
    this.calculateMainComponentHeight();

    let menu = props.getMenuSize(),
      gotopVisible = props.getTopButtonVisible(),
      activeMenu = props.getActiveMenu(),
      navExpanded = false;
    (this.state.menu !== menu ||
      this.state.gotopVisible !== gotopVisible ||
      this.state.activeMenu !== activeMenu ||
      this.state.navExpanded) &&
      !this.stateChangeBlocked &&
      this.setState({ menu, gotopVisible, activeMenu, navExpanded });
  }

  setNavExpanded = expanded => this.setState({ navExpanded: expanded });

  goto = async menu => {
    if (this.state.activeMenu !== menu.replace('Menu', '')) {
      this.setState({ activeMenu: menu.replace('Menu', '') });
      menu === 'mainComponent' &&
        this.setState({ menu: 'big', gotopVisible: false });
      menu !== 'mainComponent' &&
        this.state.menu !== 'small' &&
        this.setState({ menu: 'small', gotopVisible: window.innerWidth > 767 }); //Change menu to small
      this.stateChangeBlocked = true; //Block changing state
      this.state.menu !== 'small' && (await this.delay(50));
      this.props.goto(menu); //: setTimeout(() => this.props.goto(menu),50) //Scroll to page to the menu
      setTimeout(() => (this.stateChangeBlocked = false), 1000); //Unlocking state for change
    }
  };

  render() {
    return (
      <div>
        <Navbar
          collapseOnSelect
          fixedTop={true}
          fluid
          expanded={this.state.navExpanded}
          onToggle={this.setNavExpanded}
          className="navMenu"
          // className={this.state.menu === 'small' ? 'navMenuSmall' : 'navMenu'}
          style={{
            backgroundColor: this.state.whiteMenu ? 'white' : 'transparent'
          }}
          id="mainMenu"
        >
          <Navbar.Header>
            <Link
              to={{
                pathname: '/',
                paramx: { name: 'mainComponent' }
              }}
            >
              <Navbar.Brand>
                <img
                  id="menuImage"
                  src={this.state.whiteMenu ? logo : logoInvert}
                  alt="Qbits Logo"
                  className={
                    this.state.menu === 'small' ? 'menuImageSmall' : ''
                  }
                />
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse
            // className="menuNavbar"
            className={this.state.whiteMenu ? 'menuNavbarWhite' : 'menuNavbar'}
          >
            <Nav
              pullRight
              className={
                this.state.menu === 'big' ? 'navItems' : 'navItemsSmall'
              }
            >
              {/* Loop throught some menues predefined */}
              {menues.map((item, index) => {
                return (
                  <NavItem
                    eventKey={index}
                    active={
                      !item.component &&
                      this.state.activeMenu === item.name.toLowerCase()
                    }
                    key={index}
                  >
                    <Link
                      to={{
                        pathname: item.pathname,
                        paramx: { name: item.id }
                      }}
                      className={this.state.whiteMenu && 'whiteMenuNavItems'}
                      style={{
                        textDecoration: 'none',
                        padding: '20px 5px',
                        color: this.state.whiteMenu ? 'black' : 'white'
                      }}
                    >
                      {!item.component ? (
                        item.name
                      ) : (
                        <item.component>{item.text}</item.component>
                      )}
                    </Link>
                  </NavItem>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* Navigating to the page button */}
        <a
          id="to_top_button"
          style={{
            bottom: '30px',
            // bottom: this.state.gotopVisible ? '30px' : '-50px',
            //visibility: this.state.gotopVisible ? 'visible' : 'hidden'
            visibility: window.innerWidth > 767 ? 'visible' : 'hidden'
          }}
          data-gotop={this.state.gotopVisible}
          onClick={e => {
            // this.goto(
            //   e.target.parentElement.dataset.gotop === 'true'
            //     ? 'mainComponent'
            //     : 'servicesComponent'
            // );
            let scrollTo =
              e.target.parentElement.dataset.gotop === 'true' ? 0 : 550;
            window.scrollTo({
              behavior: 'smooth',
              top: scrollTo
            });
            // e.target.parentElement.dataset.gotop === 'true'
            //   ? window.scrollTo({ behavior: 'smooth', top: 0 })
            //   : window.scrollTo({ behavior: 'smooth', top: 500 });
            // this.setState({
            //   gotopVisible: scrollTo == 0 ? true : false
            // });
            //   this.state.gotopVisible === false &&
            //     this.setState({ gotopVisible: true });
          }}
        >
          {/* <Glyphicon style={{top: '22%', }} glyph="arrow-up" /> */}
          <img
            alt="Arrow"
            src={arrowUp_Icon}
            style={{
              width: '100%',
              transition: '0.5s 0.5s',
              transform: !this.state.gotopVisible && 'rotate(-180deg)'
            }}
          />
        </a>
      </div>
    );
  }
}

export default Menu;
