/*
  ========================================================
      PORTOFOLIO FOOTER ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import injectStyle from 'react-jss';

const style = {
  logos: {
    height: ' 130px',
    width: '200px',
    margin: '0 5px',
    borderRadius: '10px',
    transition: '1s',
    cursor: 'pointer',
    '&:hover': {
      height: '135px',
      width: '205px',
      transition: 'all 1s ease'
    }
  },

  logosrow: {
    margin: '10vh 0',
    // display: 'inline-flex',
    whiteSpace: 'nowrap',
    marginLeft: '-10%',
    width: '90vw',
    overflow: 'hidden',
    '-ms-overflow-style': 'none',
    // display: 'inline-block',
    '&::-webkit-scrollbar': {
      display: 'none',
      width: '0px'
    },
    ' user-select': 'none',
    '-moz-user-select': 'none',
    '-khtml-user-select': 'none',
    '-webkit-user-select': 'none',
    '-o-user-select': 'none'
  }
};

// let images = [
//   'elliecar.PNG',
//   'scidex.PNG',
//   '365x.PNG',
//   'lifeslice.PNG',
//   'prodware.PNG',
//   'kontent.PNG'
// ];

let companies = [
  {
    image: 'elliecar.PNG',
    link: 'http://www.ellis-car.fr/'
  },
  {
    image: 'scidex.PNG',
    link: 'https://www.scidex.co/'
  },
  {
    image: '365x.PNG',
    link: 'http://365x.io/'
  },
  {
    image: 'lifeslice.PNG',
    link: 'https://lifeslice.io/'
  },
  {
    image: 'prodware.PNG',
    link: 'https://www.prodware-group.com/en'
  },
  {
    image: 'kontent.PNG',
    link: 'https://www.kontentfilms.com/'
  },
  {
    image: 'woduels.jpg',
    link: 'http://woduels.com',
  }
];

let actualScrollPosition = 0;

class PortofolioFooter extends Component {
  scrollEvent = event => {
    event.preventDefault();
    event.target.scrollBy(event.deltaY, 0);
    event.target.parentNode.scrollBy(event.deltaY, 0);
  };

  touchStarted = event => {
    actualScrollPosition = event.touches[0].clientX;
  };

  touchMoved = event => {
    // event.target.scrollBy(actualScrollPosition - event.touches[0].clientX, 0);
    event.target.parentNode.scrollBy(
      actualScrollPosition - event.touches[0].clientX,
      0
    );
    actualScrollPosition = event.touches[0].clientX;
    // console.log('Moved: ', actualScrollPosition - event.touches[0].clientX);
  };
  render() {
    let { classes } = this.props;

    return (
      <Row
        className={classes.logosrow}
        onWheel={this.scrollEvent}
        onTouchStart={this.touchStarted}
        onTouchMove={this.touchMoved}
      >
        {companies.map((item, index) => {
          return (
            <img
              onClick={() => window.open(item.link, '_blank')}
              alt="item"
              key={index}
              className={classes.logos}
              src={require('../../images/' + item.image)}
            />
          );
        })}
      </Row>
    );
  }
}

export default injectStyle(style)(PortofolioFooter);
