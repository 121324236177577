import React, { Component } from 'react';
import LoadingImage from './loading.gif';

class Loading extends Component {
  render() {
    return (
      <div
        id="mainComponent"
        style={{
          backgroundImage: `url(${LoadingImage})`,
          height: '100vh',
          backgroundPosition: 'center',
          paddingTop: '20vh',
          backgroundColor: '#120F30',
          backgroundRepeat: 'no-repeat'
        }}
      />
    );
  }
}

export default Loading;
