import React, { Component } from 'react';
import MainComponent from './Components/Main';
import ApplyComponent from './Components/ApplyForm';
import DescriptionComponent from './Components/Description';
import FooterComponent from '../Career/Components/Footer';
import axios from 'axios';
import loadingImage from './loading_image.gif.gif';

class Loading extends Component {
  render() {
    return (
      <div>
        <img alt="img" style={{ width: '100px' }} src={loadingImage} />
      </div>
    );
  }
}

//Importing all positions
// let pos = require('../Career/Components/Positions/positions');
// pos = pos.default;

class Apply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apply: 'loading' //Can handle 3 values, false if position does not exists, loading while data is loading, or data of a particular position.
    };
  }

  componentWillMount() {
    //When component will mount we will scroll the page to the top of the screen
    window.scroll(0, 0);

    let { location = {} } = this.props;
    let { pathname = false } = location;
    let position = pathname ? pathname.split('/')[2] : undefined;
    //let selectedPosition = pos.find(item => item.name == position);

    axios({
      method: 'POST',
      url: global.api + 'position.php',
      data: {
        position
      }
    })
      .then(response => {
        if (response.data !== 'false')
          this.setState(
            {
              apply: response.data
            },
            this.setTitle
          );
        else this.setState({ apply: false });
      })
      .catch(console.log);

    // if (
    //   position !== undefined &&
    //   position !== '' &&
    //   selectedPosition !== undefined
    // ) {
    //   const { apply } = selectedPosition;
    //   this.setState({ apply }, this.setTitle);
    // }
  }

  componentDidMount() {
    console.log('Apply: ', this.state.apply);
  }
  setTitle = () =>
    this.state.apply && (document.title = 'Apply - ' + this.state.apply.title);

  render() {
    //if position does not exists
    if (this.state.apply === false) {
      return (
        <MainComponent
          title="Position does not exists. Return to positions list and select one"
          vacancies={true}
        />
      );
    }

    //While the page is loading
    if (this.state.apply === 'loading') {
      return <MainComponent title={<Loading />} vacancies={false} />;
    }

    //If the position exists we will render the data and components
    return (
      <div>
        <MainComponent title={this.state.apply.title} />
        <DescriptionComponent item={this.state.apply} />
        <ApplyComponent title={this.state.apply.title} />
        <FooterComponent />
      </div>
    );
  }
}

export default Apply;
