import React, { Component } from 'react';
import Register from './register';
import Positions from './positions';
import Loading from './loading';
import Login from './login';
import axios from 'axios';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loggedin: localStorage.getItem('credentials') !== null
    };
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin = () => {
    if (localStorage.getItem('credentials') !== null) {
      axios
        .post(global.api + 'loggedin.php', {
          ...JSON.parse(localStorage.getItem('credentials'))
        })
        .then(res => {
          //If the user is logged in
          res.data === 'auth success' &&
            this.setState({
              loading: false,
              loggedin: true
            });

          //if the user is not logged in
          res.data === 'auth failed' &&
            this.setState({
              loading: false,
              loggedin: false
            });
        })
        .catch(console.log);
    } else {
      console.log('Checking log in');
      this.setState({
        loading: false,
        loggedin: false
      });
    }
  };

  logout = () => {
    this.setState({
      loggedin: false
    });
    localStorage.removeItem('credentials');
  };
  login = () => {
    this.setState({
      loggedin: true
    });
  };

  positionAdded = () => {
    this.forceUpdate();
  };
  render() {
    if (this.state.loading) return <Loading />;
    else {
      if (!this.state.loggedin)
        return <Login login={this.login} logout={this.logout} />;
      else {
        return (
          <div>
            <div id="mainComponent" />
            <Positions
              logout={this.logout}
              changePassword={this.changePassword}
            />
            <Register positionAdded={this.positionAdded} />
          </div>
        );
      }
    }
  }
}

export default Admin;
