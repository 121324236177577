import React, { Component } from 'react';

class NotFound extends Component {
  componentDidMount() {
    document.title = 'Root not found';
  }
  render() {
    return (
      <div
        style={{
          height: '100vh',
          backgroundColor: '#380967'
          //   position: 'relative'
        }}
        id="mainComponent"
      >
        <p
          style={{
            color: 'white',
            top: '40%',
            left: '40%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '40vh',
            // position: 'absolute',
            fontSize: '2.5em'
          }}
        >
          Route not found
        </p>
      </div>
    );
  }
}

export default NotFound;
