/*
  ========================================================
      SERVICES COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Grid } from 'react-bootstrap';
import injectStyle from 'react-jss';
import HeaderItem from './Components/HeaderItem';
import BodyItem from './Components/BodyItem';
import FooterItem from './Components/FooterItem';

const style = {
  servicesMenu: {
    backgroundColor: 'whitesmoke',
    minHeight: '100vh',
    maxHeight: 'fit-content',
    position: 'relative',
    paddingBottom: '10vh'
  },
  header: {
    position: 'absolute',
    transform: 'translateY(-50%)', //Move UP 50% of its size
    // top: '-50px',
    backgroundColor: 'white',
    margin: '0 auto',
    width: '90%',
    left: '5%',
    borderRadius: '5px',
    align: 'center'
  },
  footer: {
    paddingTop: '50px',
    height: '100px',
    '@media (max-width: 992px) and (min-width: 768px) ': {
      height: '200px',
      padding: '100px'
    }
  },
  body: {
    textAlign: 'center',
    '@media (max-width: 399px)': {
      margin: ' 70% auto',
      width: '100%'
    },
    '@media (max-width: 768px) and (min-width: 400px)': {
      margin: ' 50% auto',
      width: '80%'
    },
    '@media (min-width: 767px)': {
      margin: '10% auto',
      width: '80%'
    }
  }
};

class Services extends Component {
  render() {
    let { classes } = this.props;
    return (
      <Grid fluid className={classes.servicesMenu} id="servicesComponent">
        <Grid>
          <Row>
            <Row className={classes.header}>
              <HeaderItem />
            </Row>
            <Row className={classes.body}>
              <BodyItem goto={this.props.goto} />
            </Row>
            <Row className={classes.footer}>
              <FooterItem />
            </Row>
          </Row>
        </Grid>
      </Grid>
    );
  }
}

export default injectStyle(style)(Services);
