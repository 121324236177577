/*
  ========================================================
     About Contact COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import injectStyle from 'react-jss';

const style = {
  container: {
    // paddingLeft: '11%',
    // marginTop: '35%'
  },
  socialmedia: {
    zIndex: '2',
    margin: '8px',
    padding: '0px',
    width: '120px',
    height: '65px',
    borderRadius: '5px',
    overflow: 'hidden',
    backgroundColor: 'rgba(118, 118, 219, 0.404)',
    display: 'inline-block',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  },
  contactLogo: {
    width: '100%',
    height: '100%'
  },
  contactRow: {
    margin: '0 auto'
  }
};

const contact = [
  {
    logo: require('./images/googlemap.PNG'),
    link:
      'https://www.google.com/maps/place/QBits/@41.314402,19.8037183,17z/data=!3m1!4b1!4m5!3m4!1s0x135030561724d443:0xe522ebd4d0f84f2c!8m2!3d41.314402!4d19.805907'
  },
  {
    logo: require('./images/twitter.PNG'),
    link: 'https://twitter.com/?lang=en'
  },
  {
    logo: require('./images/linkedin.PNG'),
    link: 'https://www.linkedin.com'
  },
  {
    logo: require('./images/facebook.PNG'),
    link: 'https://www.facebook.com/qbits.al/'
  },
  {
    logo: require('./images/skype.PNG'),
    link: 'skype:rigersdramalli?chat'
  },
  {
    logo: require('./images/email.PNG'),
    link: 'mailto:info@qbits.al'
  }
];

class Contact extends Component {
  render() {
    let { classes } = this.props;

    return (
      <div className={classes.container}>
        <Row className={classes.contactRow}>
          {contact.map((item, index) => {
            return (
              <Col sm={4} key={index} className={classes.socialmedia}>
                <a target="_blank" href={item.link} rel="noopener noreferrer">
                  <img
                    alt="item"
                    className={classes.contactLogo}
                    src={item.logo}
                  />
                </a>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
export default injectStyle(style)(Contact);
