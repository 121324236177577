import React, { Component } from 'react';
import Menu from './pages/Menu';
import MainPage from './pages/Main';
import CareerPage from './pages/Career';
import ApplyPage from './pages/Apply';
import NotFound from './pages/NotFound';
import AdminPage from './pages/Admin';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//global.api = 'http://qbits2.ddns.net/api/';
//global.api = 'http://localhost/qbits/api/';
global.api = 'https://qbits.al/api/';
class App extends Component {
  activeMenu = '';
  menusize = 'big';
  gotopvisible = false;

  pageScrollHandler = yOffset => {
    this.menuCoordinates.map(item => {
      let menu = item.menu !== 'main' ? item.menu : '';
      if (
        yOffset > item.top - 300 &&
        yOffset < item.bottom - 300 &&
        menu !== this.activeMenu
      ) {
        //browserHistory.replace('/' + menu);
        this.activeMenu = menu;
        document.title =
          menu !== ''
            ? 'QBits - ' + menu.charAt(0).toUpperCase() + item.menu.substr(1)
            : 'QBits';
      }
      return null;
    });
  };

  goto = (ref, yOffset = 0) => {
    // document
    //   .getElementById(ref)
    //   .scrollIntoView({ behavior: 'smooth', block: 'start' });
    const srollDistance =
      // ReactDOM.findDOMNode(this[ref]).offsetTop -
      document.getElementById(ref).offsetTop -
      document.getElementById('mainMenu').clientHeight * 1.3 +
      yOffset;

    try {
      //this line will detect if browser is supported and execute the right statement
      this.unsoportedSmoothScrollBrowser()
        ? this.scrollWindow(0, srollDistance)
        : this.scrollWindow({ top: srollDistance, behavior: 'smooth' });
    } catch (e) {
      // this.scrollWindow(0, srollDistance);
      window.scrollTo({ top: srollDistance, behavior: 'smooth' });
    }
  };
  render() {
    return (
      <Router>
        <div style={{ userSelect: 'none' }}>
          <Menu
            goto={this.goto}
            getActiveMenu={() => this.activeMenu}
            getMenuSize={() => this.menusize}
            getTopButtonVisible={() => this.gotopvisible}
            ref={e => (this.menu = e)}
            pageScrollHandler={this.pageScrollHandler}
          />
          <Switch>
            <Route
              path="/careers"
              exact
              component={() => <CareerPage goto={this.goto} />}
            />
            <Route path="/" exact component={MainPage} />
            <Route path="/apply" component={ApplyPage} />
            <Route path="/admin" component={AdminPage} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
