import React, { Component } from 'react';
import axios from 'axios';
import injectStyle from 'react-jss';
import ButtonPurple from '../Main/Components/reused/ButtonPurple';

const style = {
  input: {
    height: '50px',
    maxWidth: '550px',
    width: '90%',
    margin: '10px',
    borderRadius: '3px',
    border: '1px solid rgb(201, 200, 200)',
    padding: '15px',
    '&:disabled': {
      backgroundColor: 'white'
    }
  },
  loginDiv: {
    borderRadius: '10px',
    width: '60%',
    margin: 'auto',
    backgroundColor: '#151144',
    padding: '50px',
    '@media (max-width:576px)': {
      width: '100%'
    }
  },
  wrongPassword: {
    color: 'red',
    padding: '10px'
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      wrongPassword: false,
      sending: false
    };
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onPress = e => e.key == 'Enter' && this.login();

  login = () => {
    this.setState({
      sending: true
    });
    axios
      .post(global.api + 'login.php', {
        username: this.state.username,
        password: this.state.password
      })
      .then(res => {
        if (res.data === 'login successs') {
          localStorage.setItem(
            'credentials',
            JSON.stringify({
              username: this.state.username,
              password: this.state.password
            })
          );
          this.setState({
            wrongPassword: true,
            sending: false
          });
          this.props.login();
        }
        if (res.data === 'login failed') {
          this.props.logout();
          this.setState({
            wrongPassword: true,
            sending: false
          });
        }
      })
      .catch(console.log);
  };
  render() {
    let { classes } = this.props;
    return (
      <div
        id="mainComponent"
        style={{
          height: '100vh',
          paddingTop: '20vh',
          backgroundColor: '#120F30',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className={classes.loginDiv}>
          <input
            className={classes.input}
            name="username"
            onChange={this.onChange}
            onKeyPress={this.onPress}
            type="text"
            placeholder="username"
            value={this.state.username}
          />
          <input
            className={classes.input}
            name="password"
            onChange={this.onChange}
            onKeyPress={this.onPress}
            type="password"
            placeholder="password"
            value={this.state.password}
          />
          <ButtonPurple
            onClick={this.login}
            style={{ width: '100px' }}
            disabled={this.state.sending}
          >
            {this.state.sending ? 'Logging in...' : 'Login'}
          </ButtonPurple>
          <p
            className={classes.wrongPassword}
            style={{
              display: this.state.wrongPassword ? 'block' : 'none'
            }}
          >
            Wrong username or password. Try again
          </p>
        </div>
      </div>
    );
  }
}

export default injectStyle(style)(Login);
