/*
  ========================================================
      BODY ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import injectStyle from 'react-jss';
import ButtonPurple from '../../../reused/ButtonPurple';

const style = {
  component: {
    borderRadius: '5px',
    backgroundColor: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },
  rightSide: {
    textAlign: 'left',
    backgroundColor: '#3B0D6C',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  leftSide: {
    textAlign: 'left',
    padding: '5%',
    height: '100%',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },

  serviceTitle: {
    fontWeight: 'bold',
    marginTop: '5%',
    fontSize: '2em'
  },
  serviceDescriptiton: {
    color: 'rgba(0, 0, 0, 0.418)',
    fontSize: '1.6em',
    marginTop: '5%'
  },
  getInTouchBtn: {
    marginTop: '20%'
  },

  serviceImage: {
    padding: '7px',
    backgroundColor: 'white',
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    textAlign: 'center',
    margin: '0 50%'
  },
  servicesTitleRight: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.3em'
  },
  servicesTextRight: {
    marginTop: '5%',
    color: 'white',
    width: '90%',
    fontSize: '1.1em'
  },
  serviceItemRight: {
    padding: '5% 0',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.01)'
    }
  },
  componentBorderRow: {
    height: '1px',
    backgroundColor: 'rgba(83, 41, 155, 0.54)',
    marginLeft: '5px',
    marginTop: '5%'
  },
  readMoreText: {
    cursor: 'pointer',
    color: 'grey',
    paddingTop: '5px',
    fontSize: '1.2em',
    display: 'inline-block'
  }
};

let servicesItems = [
  {
    title: 'Custom Assignment',
    text: `What is Resource Assignment?
      With Resource Assignment our start-up assigns one or several developers to our clients, as a full-time or part-time resource they can use as if they were part of their company / start-up. These resources are employed by QBits and all the Administrative aspects are handled by us. The resources are considered as part of the client’s Organizational Chart. `,
    readMoreText: `Chart. 
    This model allows our clients to use our Developers in a dynamic and friction-less way, which is crucial to the Tech Industry. 
    QBits will still supervise the Resource and offer QA and support for its product. The supervision and intervention level between Resource and Client can be freely chosen by the Client, in order to ensure a high-efficiency and performance by the Resource. 
    Why get Resources from QBits rather than Upwork or similar platforms? 
    In difference to these freelance platforms, QBits is responsible for its Resources, and most importantly has a contractual and ‘institutional’ relationship with the clients. A freelancer may quit or turn out to be different than previously thought. A QBits resource passes through a ‘Client Approval’ process and will always be a QBits responsibility, which means that a contract governs the relationship (offering the guarantee and security of a company) while still ensuring a dynamic task-assignment, task-delivery, developer utilization for the Client (offering the flexibility of a freelancer).`,
    image: require('../image/service.png'),
    borderBottom: true,
    expandedstateName: 'customAssigment'
  },
  {
    title: 'Resource Assignment',
    text: `What is Custom Service? 
      With Custom Services QBits takes on a specified Service or Product as a task and delivers it to the Client. QBits organizes and programs the work schedule to deliver the Service for the Client on time and with the best quality. 
      The Service may be anything from a mobile app, web app, website, platform, front-end component, back-end component etc. `,
    readMoreText: `This model allows the clients to get a service or product without having to organize the works, hire developers, manage them or even get into technical details should they choose to. 
    QBits will still submit information and models to the Client in order to check if the Service or Product is according to the Client’s specifications. The service may be broken down in Phases, which allows the client to approve or reject the work gradually. 
    Why get Custom Services from QBits rather than from Freelancers? 
    In difference to freelancers, QBits is responsible for its Service, and as with the Resource Assignment has a contractual and ‘institutional’ relationship with the clients. A freelancer may quit or deliver a minimal, undocumented, faulty and buggy product. QBits will always deliver a well-documented Service, re-usable and expandable product. Why? Because QBits seeks to deliver a service or product which is good for the long-term, rather than in the short-term.`,
    image: require('../image/resource.png'),
    borderBottom: false,
    expandedstateName: 'resourceAssigment'
  }
];

class BodyItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customAssignment: false,
      resouceAssignment: false
    };
  }

  expendResourceComponent = e => {
    this.setState({
      [e.target.dataset.key]: !this.state[e.target.dataset.key]
    });
  };
  render() {
    let { classes } = this.props;
    return (
      <Row className={classes.component}>
        <Col md={6} sm={12} className={classes.leftSide}>
          <Col sm={12} className={classes.serviceTitle}>
            Services.
          </Col>
          <Col sm={12} className={classes.serviceDescriptiton}>
            We offer 2 main kinds of services, streamlined for different
            customer needs:
          </Col>
          <Col sm={12} className={classes.getInTouchBtn}>
            <ButtonPurple onClick={() => this.props.goto('contactComponent')}>
              GET IN TOUCH
            </ButtonPurple>
          </Col>
        </Col>
        <Col md={6} sm={12} className={classes.rightSide}>
          {servicesItems.map((item, index) => {
            return (
              <Row className={classes.serviceItemRight} key={index}>
                <Col sm={4} md={2}>
                  <img
                    alt="item"
                    src={item.image}
                    className={classes.serviceImage}
                  />
                </Col>
                <Col sm={8} md={10}>
                  <div className={classes.servicesTitleRight}>{item.title}</div>
                  <div className={classes.servicesTextRight}>{item.text}</div>
                  <Collapse in={this.state[`${item.expandedstateName}`]}>
                    <div className={classes.servicesTextRight}>
                      {item.readMoreText}
                    </div>
                  </Collapse>
                  <span
                    className={classes.readMoreText}
                    data-key={item.expandedstateName}
                    onClick={this.expendResourceComponent}
                  >
                    {this.state[item.expandedstateName]
                      ? 'Show Less'
                      : 'Read More'}
                  </span>
                </Col>
                {item.borderBottom && (
                  <Col sm={11} className={classes.componentBorderRow} />
                )}
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }
}

export default injectStyle(style)(BodyItem);
