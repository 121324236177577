/*
  ========================================================
      BODY ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Grid, Col, Row } from 'react-bootstrap';
import injectStyle from 'react-jss';
import { renderToStaticMarkup } from 'react-dom/server';
import ButtonPurple from '../reused/ButtonPurple';
import Contacts from './Subsomponents/contact';
import axios from 'axios';

class BackGroundImage extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        preserveAspectRatio="viewBox"
        // shape-rendering="crispEdges"
      >
        <circle fill="rgb(46, 43, 107)" cx="0" cy="900" r="900" />
      </svg>
    );
  }
}
const style = {
  container: {
    minHeight: '100vh',
    paddingBottom: '15%',
    height: 'fitContent',
    backgroundColor: '#f7f7f7',
    marginTop: '-5%',
    zIndex: '1',

    position: 'relative',
    background: `url("data:image/svg+xml,${encodeURIComponent(
      renderToStaticMarkup(<BackGroundImage />)
    )}")`,
    backgroundSize: 'cover'
  },
  body: {
    marginTop: '15%',
    // backgroundColor: 'darkblue',
    color: 'white'
  },
  title: {
    fontSize: '1.3em',
    marginBottom: '15px'
  },
  subtitle: {
    marginBottom: '15px'
  },
  input: {
    outline: 'none',
    margin: '10px 0',
    display: 'block',
    width: '80%',
    fontSize: '1.2em',
    borderRadius: '5px',
    color: 'black',
    padding: '0.8em',
    // marginLeft: '5px',
    '&::placeholder': {
      color: 'lightgrey'
    },
    '@media (max-width:768px)': {
      width: '100%'
    }
  },
  textarea: {
    height: '100px',
    resize: 'none',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'blue'
    }
  },
  footer: {
    textAlign: 'center',
    width: '100%',
    bottom: '25px',
    position: 'absolute',
    color: 'rgba(255, 255, 255, 0.8)'
    // fontSize: '0.8em'
  }
};

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      emptyValues: true,
      sending: false,
      successMessage: false,
      errorMessage: false
    };
  }

  onChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      this.checkEmptyValues
    );
  };

  checkEmptyValues = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.message === ''
    ) {
      this.setState({ emptyValues: true });
    } else this.setState({ emptyValues: false });
  };

  sendData = () => {
    if (!this.state.emptyValues) {
      console.log('Sending data');

      this.setState({
        sending: true,
        emptyValues: true
      });
      axios
        .post(global.api + 'contact.php', {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message
        })
        .then(res => {
          res.data === 'success' &&
            this.setState(
              {
                name: '',
                email: '',
                message: '',
                emptyValues: true,
                sending: false,
                successMessage: true
              },
              () => {
                setTimeout(
                  () => this.setState({ successMessage: false }),
                  5000
                );
              }
            );
          res.data === 'error' &&
            this.setState(
              {
                sending: false,
                emptyValues: false,
                errorMessage: true
              },
              () =>
                setTimeout(
                  () =>
                    this.setState({
                      errorMessage: false
                    }),
                  5000
                )
            );
        });
    }
  };
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container} id="contactComponent">
        <Grid>
          <Row className={classes.body}>
            <Col sm={12} md={7} style={{ paddingLeft: '20px' }}>
              <p className={classes.title}>Get in Touch</p>
              <p className={classes.subtitle}>
                We aim to reply whithin 48 hours
              </p>
              <input
                className={classes.input}
                type="text"
                placeholder="Your name"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
              <input
                className={classes.input}
                type="text"
                placeholder="Your email address"
                name="email"
                onChange={this.onChange}
                value={this.state.email}
              />
              <textarea
                className={`${classes.textarea} ${classes.input}`}
                placeholder="Your message here"
                name="message"
                onChange={this.onChange}
                value={this.state.message}
              />
              <p
                style={{
                  display:
                    this.state.successMessage || this.state.errorMessage
                      ? 'block'
                      : 'none',
                  fontSize: '1.5em',
                  color: this.state.successMessage ? '#71CF37' : 'red'
                }}
              >
                {this.state.successMessage
                  ? 'Contact information is received successfully'
                  : 'Error while sending your contact form, please try again.'}
              </p>

              {/* <p
                style={{
                  display: this.state.errorMessage ? 'block' : 'none',
                  fontSize: '1.5em',
                  color: 'red'
                }}
              >
                Error while sending your contact form, please try again.
              </p> */}

              <ButtonPurple
                style={{ width: '150px', padding: '12px', marginTop: '10px' }}
                disabled={this.state.emptyValues}
                onClick={this.sendData}
              >
                {this.state.sending ? 'Sending...' : 'Send'}
              </ButtonPurple>
            </Col>
            <Col sm={12} md={5} style={{ marginTop: '12%' }}>
              <Contacts />
            </Col>
          </Row>
        </Grid>
        <Col className={classes.footer} sm={12}>
          © 2018 - QBITS CO.
        </Col>
      </div>
    );
  }
}
export default injectStyle(style)(Contact);
