import React, { Component, Fragment } from 'react';
import Main from './Components/Main';
import Positions from './Components/Positions';
import Apply from './Components/Apply';
import Footer from './Components/Footer';

class Career extends Component {
  componentDidMount(props) {
    //Go  to the top of the page whenever the component will be mounted.
    window.scrollTo(0, 0);

    //Changing page title
    document.title = 'QBits - Career';
  }

  render() {
    return (
      <Fragment>
        <Main goto={this.props.goto} />
        <Positions />
        <Apply />
        <Footer />
      </Fragment>
    );
  }
}

export default Career;
