/*
  ========================================================
      MAIN COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import ButtonPurple from '../../../Main/Components/reused/ButtonPurple';
import injectStyle from 'react-jss';

const firstComponentBg = require('./images/careersImg1.jpg');
const secondComponentBg = require('./images/careersImg2.PNG');
const thirdComponentBg = require('./images/careersImg3.PNG');

const style = {
  fistComponent: {
    minHeight: '100vh',
    paddingTop: '20vh',
    backgroundColor: 'rgb(43, 40, 102)',
    backgroundImage: `url(${firstComponentBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50vw',
    backgroundPosition: 'right',
    userSelect: 'none',
    '@media (max-width:991px)': {
      backgroundSize: '70vw',
      backgroundBlendMode: 'exclusion'
    },
    '@media (max-width:576px)': {
      backgroundBlendMode: 'exclusion',
      backgroundSize: '100vw'
    }
  },
  secondComponent: {
    minHeight: '100vh',
    paddingTop: '20vh',
    backgroundColor: 'rgb(43, 40, 102)',
    backgroundImage: `url(${secondComponentBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50vw',
    backgroundPosition: 'left',
    userSelect: 'none',
    '@media (max-width:991px)': {
      backgroundBlendMode: 'exclusion',
      backgroundSize: '70vw'
    },
    '@media (max-width:576px)': {
      backgroundSize: '100vw',
      backgroundBlendMode: 'exclusion'
    }
  },
  thirdComponent: {
    // minHeight: '100vh',
    minHeight: '70vh',
    paddingTop: '20vh',
    backgroundColor: 'rgb(43, 40, 102)',
    backgroundImage: `url(${thirdComponentBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30vw',
    // backgroundSize: 'contain',

    backgroundPosition: 'right bottom',
    userSelect: 'none',
    '@media (max-width:991px)': {
      backgroundSize: '70vw',
      backgroundBlendMode: 'exclusion'
    },
    '@media (max-width:576px)': {
      backgroundSize: '100vw',
      backgroundBlendMode: 'exclusion'
    }
  },
  content: {
    margin: '10vw',
    width: '50vw'
  },
  thirdContent: {
    marginLeft: '12vw',
    width: '50vw'
  },
  title: {
    color: 'rgb(154, 144, 184)',
    fontSize: '1.2em',
    fontWeight: 'bold'
  },
  secondTitle: {
    fontSize: '2em',
    color: 'white'
  },
  thirdTitle: {
    color: 'rgb(158, 158, 233)',
    fontSize: '2em'
  },
  description: {
    fontFamily: 'Times New Roman", Times, serif',
    fontSize: '2em',
    color: 'white',
    marginTop: '30px',
    '@media (max-width:991px)': {
      fontSize: '1.5em'
    }
  },

  secondDescription: {
    color: 'white'
  },

  thirdDescription: {
    color: 'white',
    fontSize: '1.1  em',
    marginTop: '15px',
    lineHeight: '1.6'
  },
  vacanciesBtn: {
    marginTop: '50px'
  },
  purpleButton: {
    backgroundColor: 'white',
    color: 'rgb(46, 39, 66)',
    padding: '20px 0px',
    width: '180px',
    fontSize: '1.1em'
  }
};

class FirstComponent extends Component {
  render() {
    let { classes } = this.props;
    return (
      <Grid fluid className={classes.fistComponent}>
        <Row>
          <Col className={classes.content} sm={12}>
            <p className={classes.title}>
              Our goal at QBits is to create values.
            </p>
            <p className={classes.description}>
              Values through polished and professional products and services.
              Pioneering the usage of new technologies and novel applications.
              Understanding the client and group needs, and delivering tailored
              solutions.
            </p>
            <p className={classes.vacanciesBtn}>
              <ButtonPurple
                style={style.purpleButton}
                onClick={() => this.props.goto('positionsComponent')}
              >
                SEE VACANCIES
              </ButtonPurple>
            </p>
          </Col>
        </Row>
      </Grid>
    );
  }
}

class SecondComponent extends Component {
  render() {
    let { classes } = this.props;

    return (
      <Grid fluid className={classes.secondComponent}>
        <Row>
          <Col style={{ padding: '50px' }} sm={12} md={6} mdOffset={6}>
            <p className={classes.secondTitle}>
              Values through team building, resposibility and self growth.
              Identifying the common goals, creating the workflow.
            </p>
            <p className={classes.secondDescription}>
              aking responsibility, addressing the issues to ultimately grow
              professionally and take the challenge to the next level. Having
              some fun in the process too (when the bosses are not looking).
            </p>
          </Col>
        </Row>
      </Grid>
    );
  }
}

class ThirdComponent extends Component {
  render() {
    let { classes } = this.props;

    return (
      <Grid fluid className={classes.thirdComponent}>
        <Row>
          <Col className={classes.thirdContent} sm={12}>
            <p className={classes.thirdTitle}>
              Values through hard work, dedication and compassion.{' '}
            </p>
            <p className={classes.thirdDescription}>
              Being part of something bigger than the individual, building a
              mindset and work culture that will be embedded into the products,
              services and teams to come.
            </p>
            <p className={classes.thirdDescription}>
              Think you can fit in? Maybe you should apply ...
            </p>
          </Col>
        </Row>
      </Grid>
    );
  }
}

class Main extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div id="mainComponent">
        <FirstComponent classes={classes} goto={this.props.goto} />
        <SecondComponent classes={classes} />
        <ThirdComponent classes={classes} />
      </div>
    );
  }
}

export default injectStyle(style)(Main);
