import React, { Component } from 'react';

import injectStyle from 'react-jss';
import Position from './position';
import axios from 'axios';

const style = {
  container: {
    backgroundColor: 'white',
    minHeight: '20vh',
    padding: '5vw'
  },
  positionTitle: {
    fontSize: '2.5em',
    color: 'black',
    fontWeight: 'bold',
    paddingBottom: '50px'
  }
};

// const p = require('./positions');
// const pos = p.default;

class Positions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: []
    };
  }

  componentWillMount() {
    axios.get(global.api + 'positions.php').then(res => {
      this.setState({
        positions: Array.isArray(res.data) ? [...res.data] : null
      });
    });
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container} id="positionsComponent">
        <p className={classes.positionTitle}>Current Positions.</p>
        {this.state.positions !== null ? (
          this.state.positions.map((item, index) => {
            return <Position key={index} info={item} />;
          })
        ) : (
            <span style={{ fontSize: '1.5em' }}>No positions found</span>
          )}
      </div>
    );
  }
}

export default injectStyle(style)(Positions);
