/*
  ========================================================
      MAIN COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import injectStyle from 'react-jss';
import { renderToStaticMarkup } from 'react-dom/server';
import { Link } from 'react-router-dom';

import Contact from '../../../Main/Components/Contact/Subsomponents/contact';

class BackGroundImage extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        preserveAspectRatio="viewBox"
        // shape-rendering="crispEdges"
        // stroke-linejoin="round"
      >
        <circle fill="rgb(46, 43, 107)" cx="0" cy="920" r="920" />
      </svg>
    );
  }
}

const menues = [
  {
    name: 'Services.',
    path: '/',
    id: 'servicesComponent'
  },
  {
    name: 'Portofolio.',
    path: '/',
    id: 'portofolioComponent'
  },
  {
    name: 'About.',
    path: '/',
    id: 'aboutComponent'
  },
  {
    name: 'Values.',
    path: '/',
    id: 'valuesMenu'
  },
  {
    name: 'Careers.',
    path: '/career',
    id: ''
  }
];

const style = {
  container: {
    minHeight: '50vh',
    height: 'fitContent',
    backgroundColor: 'white',
    zIndex: '1',

    position: 'relative',
    background: `url("data:image/svg+xml,${encodeURIComponent(
      renderToStaticMarkup(<BackGroundImage />)
    )}")`,
    backgroundSize: 'cover',
    '@media (max-width:991px)': {
      marginTop: '50px',
      padding: '20px'
    }
  },
  menu: {
    color: 'rgb(241, 241, 241)',
    margin: '0px 5vw',
    width: 'fit-content',
    display: 'inline',
    marginRight: '10%',
    fontSize: '1.1em'
  },
  menuRow: {
    margin: '10%',
    padding: '20px',
    marginBottom: '5%',
    // width: 'fit-content',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center'
  },
  centerRow: {
    display: 'flex',
    justifyContent: 'center'
  },
  footerText: {
    margin: '20px 0px',
    color: 'white'
  }
};

class Footer extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container}>
        <Grid fluid>
          <Row className={`${classes.menuRow}`}>
            {menues.map((item, index) => {
              return (
                <Link
                  to={{
                    pathname: item.path,
                    paramx: { name: item.id }
                  }}
                  style={{ textDecoration: 'none' }}
                  key={index}
                >
                  <Col className={classes.menu}>{item.name}</Col>
                  {item.id === '' &&
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })}
                </Link>
              );
            })}
          </Row>

          <div className={classes.centerRow}>
            <Contact />
          </div>

          <div className={`${classes.centerRow} ${classes.footerText}`}>
            © 2018 - QBITS CO.
          </div>
        </Grid>
      </div>
    );
  }
}

export default injectStyle(style)(Footer);
