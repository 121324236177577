import React from 'react';
import injectStyle from 'react-jss';

const style = {
  button: {
    margin: '0px',
    padding: '10px 25px',
    border: 'none',
    outline: 0,
    backgroundColor: 'rgb(67, 64, 134) ',
    color: 'white',
    borderRadius: '40px',
    fontSize: '13px',
    '&:hover': {
      background: 'rgb(76, 73, 138)'
    },
    '&:disabled': {
      backgroundColor: 'rgba(67, 64, 134, 0.4)',
      cursor: 'not-allowed'
    }
  }
};

const ButtonPurple = ({ classes, children, className, ...otherProps }) => {
  return (
    <button className={`${classes.button} ${className}`} {...otherProps}>
      {children}
    </button>
  );
};

export default injectStyle(style)(ButtonPurple);
