import React, { Component } from 'react';
import { Row, Grid } from 'react-bootstrap';
import injectStyle from 'react-jss';

const style = {
  container: {
    minHeight: '80vh',
    position: 'relative',
    marginTop: '15vh'
  },
  title: {
    fontSize: '2.2em',
    color: '#050424',
    fontWeight: 'bold',
    marginTop: '50px',
    marginBottom: '20px'
  },
  description: {
    color: '#4E4D64'
  }
};

class Description extends Component {
  render() {
    let { classes } = this.props;
    // return <div>test</div>;
    return (
      <div className={classes.container}>
        <Grid>
          <Row>
            <p className={classes.title}>Description.</p>
            <p className={classes.description}>{this.props.item.description}</p>
            {/* Responsibilities */}
            <p className={classes.title}>Responsibilities</p>
            <ul className={classes.description}>
              {this.props.item.responsibilities.map((item, index) => {
                return <li>{item}</li>;
              })}
            </ul>
            {/* Benefits */}
            <p className={classes.title}>Benefits</p>
            <ul className={classes.description}>
              {this.props.item.benefits.map((item, index) => {
                return <li>{item}</li>;
              })}
            </ul>
            {/* Qualifications */}
            <p className={classes.title}>Qualifications.</p>
            <ul className={classes.description}>
              {this.props.item.qualifications.map((item, index) => {
                return <li>{item}</li>;
              })}
            </ul>
            {/* How to apply */}
            <p className={classes.title}>How to apply.</p>
            <ul className={classes.description}>
              {this.props.item.howtoapply.map((item, index) => {
                return <li>{item}</li>;
              })}
            </ul>
            {/* Apply bellow */}
            <p className={classes.title}>Apply bellow.</p>
            <p className={classes.description}>{this.props.item.applyBellow}</p>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectStyle(style)(Description);
