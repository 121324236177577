import React, { Component } from 'react';
import { Row, Grid } from 'react-bootstrap';
import injectStyle from 'react-jss';
import BgImage from './bgimage.jpg';
import ButtonPurple from '../../../Main/Components/reused/ButtonPurple';
import { Link } from 'react-router-dom';

const style = {
  container: {
    minHeight: '80vh',
    position: 'relative',
    backgroundImage: `url(${BgImage})`
  },
  positionTitle: {
    position: 'absolute',
    bottom: '20%',
    left: '10%',
    fontSize: '2.5em',
    color: 'white'
  },
  layer: {
    backgroundColor: '#35336B',
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: '0.8'
  }
};

class Main extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div id="mainComponent" className={classes.container}>
        <Grid fluid>
          <Row>
            <div className={classes.layer}>
              <p className={classes.positionTitle}>
                {this.props.title}
                <div>
                  <Link
                    to={{
                      pathname: '/careers',
                      paramx: { name: 'positionsComponent' }
                    }}
                  >
                    {this.props.vacancies && (
                      <ButtonPurple
                        style={{
                          backgroundColor: 'white',
                          color: 'purple',
                          borderColor: 'purple'
                        }}
                      >
                        Carrer Page
                      </ButtonPurple>
                    )}
                  </Link>
                </div>
              </p>
            </div>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectStyle(style)(Main);
