/*
  ========================================================
     Founder COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import injectStyle from 'react-jss';
import Modal from './../Modal';

const style = {
  bigDiv: {
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  },
  container: {
    textAlign: 'center',
    margin: 'auto',
    userSelect: 'none'
  },
  svg: {
    width: '100%'
  },
  mediaImage: {
    width: '30%',
    padding: '5px',
    margin: '-2px',
    borderRadius: '50%',
    backgroundColor: 'white'
  },
  details: {
    margin: '20px 0px',
    zIndex: '200',
    textAlign: 'center'
  },
  name: {
    color: 'black',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontWeight: 'bold',
    fontSize: '1.3em'
    // transform: 'translateX(8%)'
    // textAlign: 'center'
  },
  position: {
    color: '#56358A'
  },
  description: {
    lineHeight: '2'
  }
};

class Founder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      info: 'test2'
    };
  }
  showModal = () => {
    this.setState({
      showModal: true
    });
  };
  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  render() {
    let { classes } = this.props;
    let { name, position, image, id, linkedin, twitter } = this.props;
    return (
      <Col sm={3} className={classes.bigDiv}>
        <div
          id="hexDiv"
          className={classes.container}
          style={{ position: 'relative', width: '120px', height: '120px' }}
        >
          <svg
            id="hexImage"
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => !this.props.modal && this.showModal()}
          >
            <defs>
              <pattern
                id={`img${id}`}
                patternUnits="userSpaceOnUse"
                width="100"
                height="100"
              >
                <image href={image} x="-25" width="150" height="100" />
              </pattern>
            </defs>

            <path
              stroke="none"
              fill={`url(#img${id})`}
              d="M45.5 4.3301270189222a15 15 0 0 1 15 0l30.899346400575 17.839745962156a15 15 0 0 1 7.5 12.990381056767l0 35.679491924311a15 15 0 0 1 -7.5 12.990381056767l-30.899346400575 17.839745962156a15 15 0 0 1 -15 0l-30.899346400575 -17.839745962156a15 15 0 0 1 -7.5 -12.990381056767l0 -35.679491924311a15 15 0 0 1 7.5 -12.990381056767"
            />
          </svg>
          <div
            style={{
              position: 'absolute',
              zIndex: '100',
              top: '85%',
              left: '7%',
              width: 'fitContent'
            }}
          >
            <a href={twitter} target="_blank" rel="noopener noreferrer">
              <img
                alt="item"
                className={classes.mediaImage}
                src={require('./images/twitter.png')}
              />
            </a>
            <a href={linkedin} target="_blank" rel="noopener noreferrer">
              <img
                alt="item"
                className={classes.mediaImage}
                src={require('./images/linkedin.png')}
              />
            </a>
          </div>
        </div>
        <div
          className={classes.details}
          style={{ display: this.props.modal && 'none' }}
        >
          <p className={classes.name}>{name}</p>
          <p className={classes.position}>{position}</p>
        </div>
        <Modal
          show={this.state.showModal}
          info={{ ...this.props }}
          closeModal={this.closeModal}
        />
      </Col>
    );
  }
}
export default injectStyle(style)(Founder);
