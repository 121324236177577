/*
  ========================================================
      PORTOFOLIO CENTER ITEM COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import injectStyle from 'react-jss';

const style = {
  portofolioRow: {
    marginTop: '10%',
    marginLeft: '3%',
    color: 'white'
  },
  companyName: {
    fontWeight: 'bold'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '25px'
  },
  subtitle: {
    marginTop: '20px',
    fontSize: '16px'
  },
  text: {
    marginTop: '30px'
  },
  quotes: {
    width: '25px'
  },
  quotesText: {
    display: 'inline-block',
    fontSize: '1.5em',
    lineHeight: '1.2em',
    marginLeft: '5%',
    animation: 'textQuotesAnimation 3s alternate',
  },

  '@keyframes textQuotesAnimation': {
    from: {
      opacity: '0',
      transform: 'scale(0.8)'
    },
    to: {
      transform: 'scale(1)',
      opacity: '1'
    },
  },

  quotesCol: {
    padding: '0% 2%',
  },
  quoteTextDiv: {
    marginTop: '5%'
  }
};


let textQuotes = [
  `They’ve been a full stack company since 2017 — and that focus is why we chose them`,
  `We have worked for over a year with Qbits and have always been thrilled by the services they offered. They have always been very  professional, understanding, and thoughtful. Qbits has been able to quickly adapt to best match to our startup pace.`,
  `We have had the pleasure to have a long lasting partnership with Qbits. Over the past year, the Qbits team was able to develop many crucial technological features for our gaming platform. As a result, our site is now very fluid and offers a lot more features than what it originally did. When the Qbits team gets to work, they are clear and accurate about the time it will take to produce a task and upon delivery, Qbits produces outstanding documentation and summaries of the work they have done. We are very pleased with all their hard work and we would recommend the Qbits team to anyone who wants to take their product to another level, in a calm, organised and professional fashion.`
]
let textQuotesIndex = 0

class Portofolio extends Component {
  constructor(props) {
    super(props)

    this.state = {
      textQuotes: textQuotes[textQuotesIndex]
    }
    setTimeout(() => {
      textQuotesIndex = 1
      this.changeQuotes()
    }, 400)

  }

  changeQuotes = () => {
    setInterval(() => {
      textQuotesIndex > textQuotes.length - 1 && (textQuotesIndex = 0)
      console.log('changing quotes: ', textQuotesIndex)
      this.setState({
        textQuotes: textQuotes[textQuotesIndex]
      }, () => textQuotesIndex++)
    }, 8000)
  }
  render() {
    let { classes } = this.props;

    return (
      <Row className={classes.portofolioRow}>
        <Col sm={12} md={6}>
          <div className={classes.title}>Portfolio.</div>
          <div className={classes.subtitle}>
            Here are a few of the customers we’ve worked with
          </div>
          <div className={classes.text}>
            <span className={classes.companyName}> Ellis Car</span> is a French
            initiative with a focus on driver safety as well as collecting and
            analyzing data from vehicles and their environment. QBits improved
            and further developed their product to fruition across several
            platforms. Ranging from basic to advanced technicality, we moved all
            of their web back-end to a NodeJS/ CassandraDB.
          </div>

          <div className={classes.text}>
            <span className={classes.companyName}>Staker</span> is an Israeli
            startup that delivers open-source hardware and software for staking
            several wallets simultaneously. We co-developed the product using
            NodeJS and HTML/JavaScript/CSS in a Debian environment, intended to
            be used and controlled without the need of a screen with the
            objective of being user-friendly and as efficient as possible.
          </div>
          <div className={classes.text}>
            <span className={classes.companyName}>Woduels.com </span>
            is a fully functional peer to peer Esports betting platform,
            which allows gamers to wager and duel each other with WODcoins that are worth
            real money on many popular Esports titles. With Woduels.com, players are offered a fast,
             secure and easy way to compete for money and rewards on their favorite videos games.
          </div>
        </Col>
        <Col sm={12} md={6} className={classes.quotesCol} style={{ paddingTop: '5%' }}>
          <span>
            <img
              className={classes.quotes}
              alt="item"
              src={require('../../images/quotes1.png')}
            />
          </span>
          <div className={classes.quoteTextDiv}>
            <p className={classes.quotesText} key={textQuotesIndex}>
              {this.state.textQuotes}
            </p>
          </div>
          <span style={{ float: 'right' }}>
            <img
              alt="item"
              className={classes.quotes}
              src={require('../../images/quotes2.png')}
            />
          </span>
        </Col>
      </Row>
    );
  }
}

export default injectStyle(style)(Portofolio);
