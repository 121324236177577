/*
  ========================================================
     ADOUT COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Founder from './Subcomponent/Founder';
import injectStyle from 'react-jss';
import Value from './Subcomponent/Value';

const info = {
  about: {
    title: 'About',
    logo: require('../../assets/menulogo.png'),
    text: `Founded on July 31st 2017 by childhood friends Andi Basha and
    Rigers Dramalli. Since the beginning, the focus of the company
    has been delivering quality services and contracting capable
    developers to our clients, as well as getting to work more and
    more with innovative, upcoming technologies. Currently with a
    staff of eight people from various backgrounds we are able to
    handle complicated products on different technologies and
    platforms such as Web development, Mobile, IOT, Blockchain,
    Smart contracts and DApps.`
  },
  founders: [
    {
      name: 'Andi Basha',
      position: 'Co-founder and CEO',
      image: require('./images/Andi_Basha.jpg'),
      linkedin: 'https://www.linkedin.com/in/andi-basha-b7a9b09b/',
      twitter: 'http://twitter.com/AndiBasha3',
      description: `Andi is a young entrepeneur and eager believer in the dynamic technology industry. He started his career as a successful Civil Engineer with multiple projects, local and international, all of which contributed to the principles that helped shape the idea of QBits. He acts as the CEO focusing in the management, client relations and strategic decision making. He holds a B.Sc. in Civil and Environmental Engineering in the Technion Insitute of Tecnology in Haifa, Israel.`
    },
    {
      name: 'Rigers Dramalli',
      position: 'Co-founder and CTO',
      image: require('./images/Rigers_Dramall.jpg'),
      linkedin: 'https://www.linkedin.com/in/rigers-dramalli-662438107/',
      twitter: 'https://twitter.com/RDramalli',
      description: `Rigers is a tech enthusiast and partecipant in numerous start-up events held in Austria and Tirana. Following his successful career in Deloitte as a Senior Technology Consultant, driven by his lifelong dream, he co-founded the QBits start-up together with his childhood friend Andi Basha. Rigers acts as the CTO and is focused on building young and motivated developers in new and cutting-edge technologies. Rigers followed his bachelor university studies in the TUV in Vienna, Austria for Computer Science.`
    },
    {
      name: 'Alexander Bouaziz',
      position: 'Co-founder',
      image: require('./images/Alexander Bouaziz.jpeg'),
      linkedin: 'https://www.linkedin.com/in/alexbouaziz/',
      twitter: 'https://twitter.com/Bouazizalex',
      description: `Alex started building successful products at the age of 16. He holds a Masters of Eng. from MIT and has a strong experience in Product management and Design. In the last year, he has been helping companies across several spaces including Mobile Gaming, Digital Marketing whilet building his new venture Lifeslice, Scidex etc.`
    },
    {
      name: 'Philippe Bouaziz',
      position: 'Co-founder',
      image: require('./images/Philippe Bouaziz.jpeg'),
      linkedin: 'https://www.linkedin.com/in/philippe-bouaziz-ab69961/',
      twitter: 'https://twitter.com/pbouaziz',
      description: `Philippe is a serial entrepreneur that has invested in over a dozen companies. He founded the Prodware Group which is listed in Paris stock market and serves 18000 customers in over 15 countries. He is known as one of the leading personas in high tech in Europe and Israel, sitting in number of advisory boards, Engineering and business Schools.`
    }
  ],
  values: [
    {
      title: 'Teamwork',
      text:
        'We believe that instilling teamwork helps our people be more efficient, reliant, accountable, synergic, achieving a much faster growth at all levels.',
      image: require('./images/emojifamily.png')
    },
    {
      title: 'Transparency',
      text:
        'We encourage transparency inside our teams and with clients as a method of breeding trust, increasing loyalty and responsibility and helping the company grow fast.',
      image: require('./images/emojieyes.png')
    },
    {
      title: 'Innovation',
      text:
        'We integrate the innovation culture in our working environment, business model, new products and tools, structure and relationship among stuff.',
      image: require('./images/emojistorm.png')
    },
    {
      title: 'Trust and confidence',
      text:
        'We are deeply dedicated to each client by delivering tailored products and services, establishing trust and encouraging our employees to go above and beyond',
      image: require('./images/emojihandshake.png')
    },
    {
      title: 'Desire for Excellence',
      text:
        'We aim to deliver the highest quality products through a continuous process of improvement and hard working to always exceed our client’s expectations.',
      image: require('./images/emojifire.png')
    }
  ]
};

const style = {
  container: {
    minHeight: '100vh',
    paddingTop: '30px',
    backgroundColor: '#f7f7f7'
  },
  aboutTitle: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '27px'
  },
  aboutText: {
    color: 'rgba(0, 0, 0, 0.658)',
    fontSize: '1.2em'
  },
  valuesTitle: {
    fontSize: '2.2em',
    fontWeight: 'bold',
    color: 'black',
    marginTop: '30px'
    // marginLeft: '45%'
  },
  header: {
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.01)'
    }
  },
  logo: {
    width: '150px',
    maxWidth: '100%',
    marginTop: '30%',
    '@media (max-width:992px)': {
      marginTop: '5px'
    }
  }
};

class About extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.container} id="aboutComponent">
        <Grid>
          <Row>
            <Col sm={12} md={10} className={classes.header}>
              <p className={classes.aboutTitle}>{info.about.title}</p>
              <p className={classes.aboutText}>{info.about.text} </p>
            </Col>
            <Col smHidden={true} sm={12} md={2} className={classes.header}>
              <img alt="img" className={classes.logo} src={info.about.logo} />
            </Col>
          </Row>
          <Row style={{ marginTop: '10vh' }}>
            <Col sm={12}>
              {info.founders.map((item, index) => {
                return (
                  <Founder key={index} {...item} id={index} modal={false} />
                );
              })}
            </Col>
            <Col sm={12} id="valuesMenu">
              <p className={classes.valuesTitle}>Values</p>
              <Row>
                {info.values.map((item, index) => (
                  <Value key={index} value={item} />
                ))}
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
export default injectStyle(style)(About);
