/*
  ========================================================
      MAIN COMPONENT
  ========================================================
*/

import React, { Component } from 'react';
import { Row, Col, Grid, Collapse } from 'react-bootstrap';
import ButtonPurple from '../reused/ButtonPurple';
import injectStyle from 'react-jss';
import './fonts.css';
import '../../assets/fonts/BearingPointSans.woff';

/*IMPORTING FONTS */
import BearingPointFont from '../../assets/fonts/BearingPointSans.woff';
import FSAlbertFont from '../../assets/fonts/FSAlbert.woff';

const style = {
  refresh: false,
  mainMenu: {
    minHeight: '100vh',
    height: 'fit-content',
    paddingBottom: '150px',

    //marginLeft: '10px',
    backgroundColor: 'rgb(18, 15, 48)',
    // backgroundColor: 'red'
    '@media (max-width:768px)': {
      paddingBottom: '300px'
    },
    '@media (max-width:996px) AND (min-width: 768px)': {
      paddingBottom: '200px'
    }
  },
  // '@font-face': {
  //   fontFamily: 'BearingPoint',
  //   fontStyle: 'normal',
  //   fontWeight: '400',
  //   src: BearingPointFont,
  // },
  // '@font-face': {
  //   fontFamily: 'FSAlbert',
  //   fontStyle: 'normal',
  //   fontWeight: '400',
  //   src: FSAlbertFont
  // },
  hireText: {
    margin: '15px',
    color: 'white',
    fontSize: '1.2em',
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  hireTitle: {
    fontFamily: 'BearingPoint, FSAlbert',
    marginTop: '150px',
    fontSize: '4em',
    fontWeight: 'bold',
    marginBottom: '3vh',
    display: 'inline-block',
    maxWidth: '100vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    ' -webkit-text-fill-color': 'transparent',
    transition: 'background 1s',
    '@media (max-width:450px)': {
      fontSize: '2.2em'
    },
    '@media (max-width:576px) AND (min-width:451px) ': {
      fontSize: '2.5em'
    },
    '@media (max-width:678px) AND (min-width:577px) ': {
      fontSize: '3em'
    }
  },
  titleSpan: {
    borderRight: '.15em solid orange',
    marginLeft: '15px',
    overflow: 'hidden',
    paddingRight: '5px',
    lineHeight: '1.2',
    fontWeight: 'bold',
    '-webkit-background-clip': 'text',
    ' -webkit-text-fill-color': 'transparent',
    animation:
      'hireTextKeyframe linear 3s forwards, blink-caret 0.4s step-end infinite;'
  },
  hireDescription: {
    position: 'absolute',
    left: '-100%',
    opacity: '0',
    padding: '50px',
    width: '100%',
    animation: 'mainPageDescriptionTitleAnimation 2.5s forwards'
  },
  '@keyframes mainPageDescriptionTitleAnimation': {
    from: {
      left: '-100%',
      opacity: '0'
    },
    to: {
      left: '0%',
      opacity: '1',
      position: 'relative'
    }
  },
  '@keyframes blink-caret': {
    '0%': 'border-color: transparent',
    '50%': 'border-color: orange',
    '100%': 'border-color: transparent'
  },

  '@keyframes hireTextKeyframe': {
    '0%': {
      width: '0%'
    },
    '100%': {
      width: '100%'
    }
  },
  '@keyframes mainPageContactBtnAnimation': {
    '0%': {
      opacity: '0',
      position: 'absolute'
    },
    '30%': {
      opacity: '0',
      position: 'absolute',
      left: '300px'
    },
    '100%': {
      opacity: '1',
      position: 'absolute',
      left: '0px'
    }
  },
  ButtonPurpleDiv: {
    marginTop: '10vh',
    animation: 'mainPageContactBtnAnimation 2.5s forwards'
  }
};

const info = {
  title: [
    {
      text: 'Hire great developers?',
      bgimage: 'linear-gradient(135deg,#93d522 10%,#033e8e 90%)'
    },
    {
      text: 'Launch your app?',
      bgimage: 'linear-gradient(135deg,#29d5ff 10%,#6c03a8 90%)'
    },
    {
      text: 'Build your platform?',
      bgimage: 'linear-gradient(135deg,#ff48a6 10%,#520881 90%)'
    },
    {
      text: 'Find a solution?',
      bgimage: 'linear-gradient(135deg,#3f87a6 10%,#f69d3c 90%)'
    }
  ],
  description: ` We strive to provide high quality Web and other Application
  Development services.We aim to showcase the potential of
  Albanian developers ever-growing tech industry`
};

let titleindex = 1;
let titleArrayIndex = 0;
let titleinverval = null;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: info.title[0].text,
      bgimage: info.title[0].bgimage,
      description: info.description,
      refresh: false,
    };
    this.changeTitle();
  }


  changeTitle = () => {
    setInterval(() => {
      info.title.length <= titleindex && (titleindex = 0);
      this.setState({
        title: info.title[titleindex].text,
        bgimage: info.title[titleindex].bgimage,
        refresh: !this.state.refresh
      });
      titleindex++;
    }, 5000);
  };


  render() {
    let { classes } = this.props;
    return (
      <div className={classes.mainMenu} id="mainComponent">
        <Grid>
          <Row>
            <Col sm={12}>
              <div
                className={`${classes.hireTitle} ${classes.hireText}`}
                style={{ display: 'block' }}
                style={{
                  backgroundImage: this.state.bgimage
                }}
              >
                Want to
              </div>
            </Col>
            <Col sm={12}>
              <div
                className={`${classes.hireTitle} ${classes.hireText}`}
                style={{ marginTop: '10px' }}
              >
                <div
                  key={this.state.refresh}
                  className={classes.titleSpan}
                  style={{ backgroundImage: this.state.bgimage }}
                >
                  {this.state.title}
                </div>
              </div>
            </Col>
            {/* <Col sm={6}>
              <div className={`${classes.hireText} ${classes.hireDescription}`}>
                {this.state.description}
              </div>
              <div />
            </Col> */}
            <Col sm={12}>
              <div className={` ${classes.hireText}`}>
                <ButtonPurple
                  onClick={() => this.props.goto('contactComponent')}
                >
                  GET IN TOUCH
                </ButtonPurple>
              </div>
            </Col>


          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectStyle(style)(Main);
